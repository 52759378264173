import React, { useState, useEffect, useContext, useRef } from "react";
import "./date-section.scss";
import { SocketContext } from "../../../../app/socket";
import Draggable from "react-draggable";
import { useEventContext } from "../../eventContext";
import { useSelector } from "react-redux";

function RoundHalfDown(num) {
  return Math.round(num / 15) * 15;
}

const TimeSlot = ({ hour, isNotWorking, workingHours }) => (
  <div
    className={`time-slot ${
      workingHours && workingHours.length && isNotWorking ? "not-working" : ""
    }`}
  >
    <span className="time-slot__hour">{hour}:00</span>
  </div>
);

function getMinutes(timeString) {
  const timeParts = timeString.split(":");
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);
  return hours * 60 + minutes;
}

function formatTime(date) {
  if (!(date instanceof Date) || isNaN(date)) {
    return "";
  }
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
}

function TimeLine({ eventDepartment, selectedClassrooms, calendarDate }) {
  const socketContext = useContext(SocketContext);

  const {
    eventStartDate,
    setEventStartDate,
    eventEndDate,
    setEventEndDate,
    eventId,
    finalTestForErrors,
    setFinalTestForErrors,
    setNoFinalErrors,
    setErrorMsg,
    eventRepeatType,
    setEventRepeatType,
    eventType,
    setEventOld,
  } = useEventContext();

  const generalValues = useSelector((state) => state.profile.generalValues);

  const [workingDays, setWorkingDays] = useState([]);
  const [workingHours, setWorkingHours] = useState([]);
  const [isNotWorkingDayFlag, setIsNotWorkingDayFlag] = useState(false);
  const [isNotWorkingHoursFlags, setIsNotWorkingHoursFlags] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const scrollableRef = useRef(null);

  // Load workingDays and workingHours from generalValues
  useEffect(() => {
    if (
      generalValues &&
      generalValues.length &&
      generalValues.find((value) => value.name === "working_days")
    ) {
      try {
        let savedWorkingDays = generalValues.find(
          (value) => value.name === "working_days"
        ).value;
        setWorkingDays(JSON.parse(savedWorkingDays));
      } catch (e) {
        console.log(e);
      }
    }
    if (
      generalValues &&
      generalValues.length &&
      generalValues.find((value) => value.name === "working_hours")
    ) {
      try {
        let savedWorkingHours = generalValues.find(
          (value) => value.name === "working_hours"
        ).value;
        setWorkingHours(JSON.parse(savedWorkingHours));
      } catch (e) {
        console.log(e);
      }
    }
  }, [generalValues]);

  // Recalculate not-working classes whenever calendarDate, workingDays, or workingHours change
  useEffect(() => {
    if (!calendarDate) return;

    const dayOfWeek = calendarDate ? new Date(calendarDate).getDay() : 1; // Assume Monday if calendarDate is null

    const isDayNotWorking = isNotWorkingDay(dayOfWeek);
    setIsNotWorkingDayFlag(isDayNotWorking);

    const flags = [...Array(24).keys()].map((hour) => {
      const isHourNotWorking = isNotWorkingHour(dayOfWeek, hour);
      return isHourNotWorking;
    });
    setIsNotWorkingHoursFlags(flags);
  }, [calendarDate, workingDays, workingHours]);

  const isNotWorkingDay = (dayOfWeek) => {
    if (!workingDays || workingDays.length === 0) return false;
    return !workingDays.includes(dayOfWeek);
  };

  const isNotWorkingHour = (dayOfWeek, hour) => {
    if (!workingHours || workingHours.length === 0) return false;
    const workingHoursForDay = workingHours[dayOfWeek];
    if (!workingHoursForDay) return true; // No working hours specified for this day

    const startMinutes = getMinutes(workingHoursForDay.start_hour);
    const endMinutes = getMinutes(workingHoursForDay.finish_hour);
    const currentMinutes = hour * 60;

    return currentMinutes < startMinutes || currentMinutes >= endMinutes;
  };

  useEffect(() => {
    getCurrentEvents();
  }, [eventStartDate]);

  useEffect(() => {
    if (finalTestForErrors) {
      setFinalTestForErrors(false);
      if (checkForErrors() && checkForErrors().length) {
        setNoFinalErrors("has-error");
        setErrorMsg(checkForErrors());
      } else {
        setNoFinalErrors("no-errors");
        setErrorMsg("no-error");
      }
    }
  }, [finalTestForErrors]);

  const checkForErrors = () => {
    function doEventsOverlap(event1, event2) {
      // Extract hours and minutes from the dates
      const start1 =
        new Date(event1.start).getHours() * 60 +
        new Date(event1.start).getMinutes();
      const end1 =
        new Date(event1.end).getHours() * 60 +
        new Date(event1.end).getMinutes();
      const start2 =
        new Date(event2.start).getHours() * 60 +
        new Date(event2.start).getMinutes();
      const end2 =
        new Date(event2.end).getHours() * 60 +
        new Date(event2.end).getMinutes();

      // Compare only hours and minutes (converted to minutes)
      return !(end1 <= start2 || start1 >= end2);
    }
    let msg = "";
    const overlapFound = currentEvents.some((event) => {
      let event1 = {
        start: new Date(event.start_at),
        end: new Date(event.finish_at),
      };

      let event2 = {
        start: new Date(eventStartDate),
        end: new Date(eventEndDate),
      };

      if (doEventsOverlap(event1, event2)) {
        let classroomsOverlap = false;
        let professorOverlap = false;
        let departmentOverlap = false;

        let type = "μάθημα";
        if (eventType == "exam") {
          type = "διαγώνισμα";
        } else if (eventType == "test") {
          type = "τεστ";
        }

        let overlapType = "μάθημα";
        if (event.type == "exam") {
          overlapType = "διαγώνισμα";
        } else if (event.type == "test") {
          overlapType = "τεστ";
        }

        try {
          let eventClassrooms = JSON.parse(event.classroom_id);
          eventClassrooms.map((classroom) => {
            if (eventClassrooms.includes.classroom) {
              classroomsOverlap = true;
            }
          });
        } catch (e) {
          console.log(e);
        }

        if (classroomsOverlap) {
          msg =
            "Το επιλεγμένο χρονοδιάγραμμα για το " +
            type +
            " σας επικαλύπτει ένα άλλο " +
            overlapType +
            " με την ίδια αίθουσα.";
        } else if (departmentOverlap) {
          msg =
            "Το επιλεγμένο χρονοδιάγραμμα για το " +
            type +
            " σας επικαλύπτει ένα άλλο " +
            overlapType +
            " με το ίδιο τμήμα.";
        } else if (professorOverlap) {
          msg =
            "Το επιλεγμένο χρονοδιάγραμμα για το " +
            type +
            " σας επικαλύπτει ένα άλλο " +
            overlapType +
            " με τον ίδιο καθηγητή.";
        } else {
          msg =
            "Το επιλεγμένο χρονοδιάγραμμα για το " +
            type +
            " σας επικαλύπτει ένα άλλο " +
            overlapType;
        }

        return true;
      }

      return false; // Continues to the next item
    });

    return msg;
  };

  const getCurrentTime = () => {
    const now = new Date();
    return formatTime(now);
  };

  const getCurrentTimePlusTwoHours = () => {
    const now = new Date();
    now.setHours(now.getHours() + 2);
    return formatTime(now);
  };

  const handleStartTimeChange = (e) => {
    let startTime = e.target.value;
    let startTimeMinutes = getMinutes(startTime) - 1440;
    setEventStartMinutes(startTimeMinutes);
    setStartTime(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    let endTime = e.target.value;
    let endTimeMinutes = getMinutes(endTime) - 1440;
    setEventEndMinutes(endTimeMinutes);
    setEndTime(e.target.value);
  };

  const defaultStartTime = eventStartDate
    ? new Date(eventStartDate)
    : new Date();
  let defaultEndTime;

  if (eventEndDate) {
    defaultEndTime = new Date(eventEndDate);
    // Check if dates are the same and ignore time part
    if (defaultEndTime.toDateString() === defaultStartTime.toDateString()) {
      // Adjust end time to be 2 hours ahead
      defaultEndTime = new Date(
        defaultStartTime.getTime() + 2 * 60 * 60 * 1000
      );
    }
  } else {
    // No end date provided, set to 2 hours ahead
    defaultEndTime = new Date(defaultStartTime.getTime() + 2 * 60 * 60 * 1000);
  }

  const [startTime, setStartTime] = useState(formatTime(defaultStartTime));
  const [endTime, setEndTime] = useState(formatTime(defaultEndTime));

  const [eventStartMinutes, setEventStartMinutes] = useState(
    getMinutes(startTime) - 1440
  );
  const [eventEndMinutes, setEventEndMinutes] = useState(
    getMinutes(endTime) - 1440
  );

  useEffect(() => {
    setStartTime(getEventStartTime());
    if (eventStartDate.length < 24) {
      setEventStartDate((currentDateTime) => {
        const datePart = currentDateTime.split(" ")[0];
        const newDateTime = `${datePart} ${getEventStartTime()}:00`; // Assuming seconds are always 00
        return newDateTime;
      });
    }
  }, [eventStartMinutes]);

  useEffect(() => {
    setEndTime(getEventEndTime());
    if (eventEndDate.length < 24) {
      setEventEndDate((currentDateTime) => {
        const datePart = currentDateTime.split(" ")[0];
        const newDateTime = `${datePart} ${getEventEndTime()}:00`; // Assuming seconds are always 00
        return newDateTime;
      });
    }
  }, [eventEndMinutes]);

  useEffect(() => {
    getCurrentEvents();
    getEventStartTime();
  }, [selectedClassrooms]);

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = 500;
    }
  }, [scrollableRef]);

  const getEventStartTime = () => {
    let startHour = Math.floor((eventStartMinutes + 1440) / 60);
    let startMinutes = (eventStartMinutes + 1440) % 60;
    if (startHour < 10) {
      startHour = "0" + startHour;
    }
    if (startMinutes < 10) {
      startMinutes = "0" + Math.abs(startMinutes);
    }
    return `${startHour}:${startMinutes}`;
  };

  const getEventEndTime = () => {
    let endHour = Math.floor((eventEndMinutes + 1440) / 60);
    let endMinutes = (eventEndMinutes + 1440) % 60;
    if (endHour < 10) {
      endHour = "0" + endHour;
    }
    if (endMinutes < 10) {
      endMinutes = "0" + Math.abs(endMinutes);
    }
    return `${endHour}:${endMinutes}`;
  };

  const getCurrentEvents = () => {
    let actualDate = eventStartDate ? eventStartDate : calendarDate;
    actualDate = new Date(actualDate);
    if (actualDate && !isNaN(actualDate)) {
      const args = {
        day: actualDate.getDate(),
        month: actualDate.getMonth(),
        year: actualDate.getFullYear(),
        classroom_id: selectedClassrooms,
        department_id: eventDepartment,
        event_id: eventId ? eventId : "",
      };

      const getEventsListener = (data) => {
        setCurrentEvents(data);
      };

      socketContext.socket.off("eventsAvailability", getEventsListener); // Prevent multiple listeners
      socketContext.socket.on("eventsAvailability", getEventsListener);
      socketContext.socket.emit("getEventsAvailability", args);
      socketContext.socket.off("refreshEventsAvailability");
      socketContext.socket.on("refreshEventsAvailability", () => {
        socketContext.socket.emit("getEventsAvailability", args);
      });
    }
  };

  const handleDragStop = (newStartPixel) => {
    const duration = eventEndMinutes - eventStartMinutes;
    let newStartMinutes = Math.floor(RoundHalfDown(newStartPixel));

    setEventStartMinutes(newStartMinutes);
    setEventEndMinutes(newStartMinutes + duration);
  };

  const handleDrag = (e, data) => {
    const { y } = data;
    const buffer = 30; // Distance from the bottom edge at which scrolling should start.
    const { scrollTop, offsetHeight } = scrollableRef.current;
    if (y + 1440 - scrollTop > offsetHeight - buffer) {
      scrollableRef.current.scrollTop += buffer;
    }
  };

  const populateCurrentEvents = () => {
    return currentEvents.map((event, key) => {
      const eventStart = new Date(event.start_at);
      const eventEnd = new Date(event.finish_at);
      const topPosition = eventStart.getHours() * 60 + eventStart.getMinutes();
      const bottomPosition = eventEnd.getHours() * 60 + eventEnd.getMinutes();
      const height = bottomPosition - topPosition;
      return (
        <div
          key={`event-${key}`}
          className="time-slot__taken"
          style={{
            position: "absolute",
            top: `${topPosition}px`,
            height: `${height}px`,
            width: "100%",
          }}
        >
          <span className="time-slot__taken-title">
            {event.title} -{" "}
            {event.type === "lecture"
              ? "Μάθημα"
              : event.type === "exam"
              ? "Διαγώνισμα"
              : "Τεστ"}
          </span>
        </div>
      );
    });
  };

  // const isPastDate = () => {
  //   const currentDate = new Date();
  //   const selectedDate = new Date(calendarDate);
  //   return currentDate > selectedDate;
  // };

  const isPastDate = () => {
    if (eventType == "test") {
      const currentDate = new Date();
      const selectedDate = new Date(calendarDate);

      // Compare only the year, month, and day parts
      if (
        currentDate.getFullYear() > selectedDate.getFullYear() ||
        (currentDate.getFullYear() === selectedDate.getFullYear() &&
          currentDate.getMonth() > selectedDate.getMonth()) ||
        (currentDate.getFullYear() === selectedDate.getFullYear() &&
          currentDate.getMonth() === selectedDate.getMonth() &&
          currentDate.getDate() > selectedDate.getDate())
      ) {
        return true;
      }

      return false;
    }
  };

  useEffect(() => {
    if (
      window.location.hostname.includes("simmetria") ||
      window.location.hostname.includes("localhost")
    ) {
      if (isPastDate()) {
        setEventRepeatType("");
        setEventOld(true);
        console.log(" setting event old to true");
      } else {
        console.log(" setting it to false ");
        setEventOld(false);
      }
    }
  }, [calendarDate]);

  return (
    <div
      className={`timeline-wrapper ${
        workingHours && workingHours.length && isNotWorkingDayFlag
          ? "not-working"
          : ""
      }`}
    >
      {isPastDate() && (
        <div className="timeline-wrapper__old-date">
          {/* <span>Προηγ</span> */}
        </div>
      )}
      {!isPastDate() ? (
        <div className="time">
          <div className="item">
            <label htmlFor="startTime">Ώρα έναρξης:</label>
            <input
              type="time"
              className="input"
              id="startTime"
              value={startTime}
              onChange={handleStartTimeChange}
            />
          </div>
          <div className="item">
            <label htmlFor="endTime">Ώρα λήξης:</label>
            <input
              type="time"
              className="input"
              id="endTime"
              value={endTime}
              onChange={handleEndTimeChange}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {!isPastDate() ? (
        <div ref={scrollableRef} className="timeline no-scollbar">
          <div style={{ position: "relative", height: "1440px" }}>
            {[...Array(24).keys()].map((hour) => (
              <TimeSlot
                key={"day-hour" + hour}
                hour={hour}
                isNotWorking={isNotWorkingHoursFlags[hour]}
                workingHours={workingHours}
              />
            ))}
            <DraggableEvent
              getEventStartTime={getEventStartTime}
              eventStartMinutes={eventStartMinutes}
              eventEndMinutes={eventEndMinutes}
              onDragStop={handleDragStop}
              getEventEndTime={getEventEndTime}
              handleDrag={handleDrag}
            />
            <div className="time-slot__taken-container">
              {populateCurrentEvents()}
            </div>
            <div
              className="time-slot__current"
              style={{
                top: `${
                  new Date().getHours() * 60 + new Date().getMinutes()
                }px`,
              }}
            ></div>
          </div>
        </div>
      ) : (
        ""
      )}
      {(eventType === "lecture" ||
        eventType == "exam" ||
        eventType == "test") &&
      !isPastDate() ? (
        <div className="repeat-type">
          <span className="label">Επανάληψη μαθήματος</span>
          <select
            value={eventRepeatType}
            onChange={(e) => setEventRepeatType(e.target.value)}
            className="input"
          >
            <option value="">Καμία επανάληψη</option>
            <option value="weekly">Εβδομαδιαία επανάληψη</option>
          </select>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const DraggableEvent = ({
  getEventStartTime,
  eventStartMinutes,
  eventEndMinutes,
  onDragStop,
  getEventEndTime,
  handleDrag,
}) => {
  return (
    <Draggable
      axis="y"
      bounds="parent"
      position={{ x: 0, y: RoundHalfDown(eventStartMinutes) }}
      onStop={(e, data) => onDragStop(data.y)}
      onDrag={handleDrag}
    >
      <div
        className="time-slot__event"
        style={{ height: `${eventEndMinutes - eventStartMinutes}px` }}
      >
        <span className="time-slot__event-title">
          {getEventStartTime()} - {getEventEndTime()}
        </span>
      </div>
    </Draggable>
  );
};

export default TimeLine;
