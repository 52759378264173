import React, { useState, useEffect, useContext, useRef } from "react";
import "./announcement-section.scss";
import { SocketContext } from "../../../../app/socket";
import { useEventContext } from "../../eventContext";
import { getGeneralSettingValue } from "../../../../utils/generalUtils";
import { useSelector } from "react-redux";
import RichTextEditor from "../../../RichTextEditor/richeTextEditor";
import { calls } from "../../../../config/db_config";
import ClassSelector from "../../../ClassSelector/classSelector";

function AnnouncementSection({ eventType, isEdit }) {
  const socketContext = useContext(SocketContext);
  const generalSettings = useSelector((state) => state.profile.generalSettings);
  const envVars = useSelector((state) => state.profile.environmentVars);
  const [classes, setClasses] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [searchClass, setSearchClass] = useState("");

  const { announcementTitle, setAnnouncementTitle, saveAnnouncementText } =
    useEventContext();
  const {
    eventClass,
    setEventClass,
    forProfessors,
    forStudents,
    forParents,
    setForProfessors,
    setForParents,
    setForStudents,
    anClass,
    setAnClass,
    anGrade,
    setAnGrade,
  } = useEventContext();
  const { eventDepartments, setEventDepartments } = useEventContext();

  const { testForErrors, setTestForErrors } = useEventContext();
  const { setNoErrors } = useEventContext();

  const eventClassRef = useRef(0);
  const eventTypeRef = useRef(0);

  const [showTitleError, setShowTitleError] = useState(false);
  const [showDepartmentError, setShowDepartmentError] = useState(false);
  const [showProfError, setShowProfError] = useState(false);

  const firstTimeRef = useRef(0);

  const activeClassRef = useRef(null); // Ref for the active class element
  const classesContainerRef = useRef(null); // Ref for the classes container

  useEffect(() => {
    if (firstTimeRef.current > 0 && testForErrors) {
      console.log(" testing for errors ");
      if (eventDepartments.length) {
        setShowTitleError(false);
        setShowDepartmentError(false);
        setShowProfError(false);

        setNoErrors(true);
      } else {
        console.log(" there is an error ");
        // if (!announcementTitle) {
        //   setShowTitleError(true);
        // }
        // if (!eventDepartments.length) {
        //   setShowDepartmentError(true);
        // }
        setEventDepartments([-1]);
        setShowTitleError(false);
        setShowDepartmentError(false);
        setShowProfError(false);

        setNoErrors(true);
        // setTestForErrors(false);
      }
    } else {
      firstTimeRef.current++;
    }
  }, [testForErrors]);

  //   useEffect(() => {
  //     if (announcementTitle) {
  //       setShowTitleError(false);
  //       setTestForErrors(false);
  //     } else {
  //       setTestForErrors(false);
  //     }
  //   }, [announcementTitle]);

  useEffect(() => {
    if (eventDepartments.length) {
      setShowDepartmentError(false);
      setTestForErrors(false);
    } else {
      setTestForErrors(false);
    }
  }, [eventDepartments]);

  useEffect(() => {
    if (eventTypeRef.current > 0 && !isEdit) {
      setEventDepartments([]);
    }
    eventTypeRef.current++;
  }, [eventType]);

  useEffect(() => {
    const cleanUpGetClasses = getClasses();
    return () => {
      cleanUpGetClasses();
    };
  }, [searchClass]);

  useEffect(() => {
    if (eventClassRef.current > 0) {
      setEventDepartments([]);
    }
    eventClassRef.current++;
    const cleanUpGetDepartments = getDepartments();
    return () => {
      cleanUpGetDepartments();
    };
  }, [eventClass]);

  useEffect(() => {
    if (isEdit && activeClassRef.current) {
      setTimeout(() => {
        activeClassRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 200);
    }
  }, [classes, eventClass, isEdit]);

  const getClasses = () => {
    let args = { search: searchClass };

    const getClassesListener = (data) => {
      if (data && data[0] && (!eventClass || eventClass === -1)) {
        setEventClass(data[0].class_id);
      }
      setClasses(data);
    };

    const refreshClassesListener = () => {
      socketContext.socket.emit("getClasses", args);
    };

    socketContext.socket.on("classes", getClassesListener);
    socketContext.socket.emit("getClasses", args);
    socketContext.socket.on("refreshClasses", refreshClassesListener);
    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getClasses", getClassesListener);
      socketContext.socket.off("classes", getClassesListener);
      socketContext.socket.off("refreshClasses", refreshClassesListener);
    };
  };

  const selectClass = (classId) => {
    const temp = [];
    temp.push(classId);
    setAnClass(temp);
  };

  const populateClasses = () => {
    return classes.map((classItem, i) => {
      return (
        <div
          key={"announcementClassItem" + i}
          onClick={() => selectClass(classItem.class_id)}
          className={
            "classes__list-item " +
            (anClass && anClass.length && anClass[0] == [classItem.class_id]
              ? "active"
              : "")
          }
          ref={eventClass === classItem.class_id ? activeClassRef : null}
        >
          <span className="class-name">{classItem.class_name}</span>
          {getGeneralSettingValue(generalSettings, "only_grades") ? (
            ""
          ) : (
            <span className="grade">{classItem.grade_name}</span>
          )}
        </div>
      );
    });
  };

  const getDepartments = () => {
    const getDepartmentsListener = (data) => {
      setDepartments(data);
    };

    socketContext.socket.on("departments", getDepartmentsListener);

    let args = { class_id: eventClass };
    socketContext.socket.emit("getDepartments", args);

    const refreshDepartmentsListener = () => {
      socketContext.socket.emit("getDepartments", args);
    };
    socketContext.socket.on("refreshDepartments", refreshDepartmentsListener);

    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getDepartments", getDepartmentsListener);
      socketContext.socket.off("departments", getDepartmentsListener);
      socketContext.socket.off(
        "refreshDepartments",
        refreshDepartmentsListener
      );
    };
  };

  const populateDepartments = () => {
    return departments.map((departmentItem, i) => {
      return (
        <div
          key={"announcementDepartmentItem" + i}
          className={
            "item " +
            (eventDepartments.includes(departmentItem.department_id)
              ? "active"
              : "")
          }
          onClick={() => departmentClick(departmentItem.department_id)}
        >
          {departmentItem.department_name}
        </div>
      );
    });
  };

  const departmentClick = (clickedId) => {
    let temp = eventDepartments;
    if (eventType === "lecture" && eventDepartments.length) {
      setEventDepartments([]);
      temp = [];
    }
    if (eventDepartments.length === 1 && eventDepartments[0] === -1) {
      temp = [];
    }
    if (eventDepartments.includes(clickedId)) {
      const newDepartmentId = eventDepartments.filter((id) => id !== clickedId);
      if (newDepartmentId.length === 0) {
        if (eventType !== "lecture") {
          temp = [-1];
        }
      } else {
        temp = newDepartmentId;
      }
    } else {
      temp = [...temp, clickedId];
    }
    setEventDepartments(temp);
  };

  return (
    <div className={"announcement-section no-scrollbar"}>
      <span className="title"></span>
      <div className="left">
        <div className="section__input title-config">
          <span className="label">
            Περιγραφή
            {showTitleError ? (
              <span className="error-msg">Εισάγετε τίτλο</span>
            ) : (
              ""
            )}
          </span>
          <RichTextEditor
            key={"rich text editor announcement "}
            imagesEndpoint={`${envVars.DB_SCHOOL_NAME}/announcements`}
            postImagesEndPoint={calls.submitAnnouncementImages}
            text={announcementTitle}
            setText={setAnnouncementTitle}
            saveFiles={saveAnnouncementText}
            fileNameStart="announcements"
          />
          {/* <input
            className="input"
            placeholder="Εισάγεται έναν τίτλο"
            value={eventTitle}
            onChange={(e) => setEventTitle(e.target.value)}
          /> */}
        </div>
        {!getGeneralSettingValue(generalSettings, "only_grades") ? (
          <ClassSelector
            classes={classes}
            selectedClasses={anClass}
            setSelectedClasses={setAnClass}
            selectedGrades={anGrade}
            setSelectedGrades={setAnGrade}
          />
        ) : (
          <span className="label">
            Επιλέξτε{" "}
            {getGeneralSettingValue(generalSettings, "only_grades")
              ? "τάξη"
              : "μάθημα"}
          </span>
        )}
        {getGeneralSettingValue(generalSettings, "only_grades") ? (
          <div className="classes section__input" ref={classesContainerRef}>
            <input
              className="input"
              value={searchClass}
              onChange={(e) => setSearchClass(e.target.value)}
              placeholder={
                "Αναζητήστε " +
                (getGeneralSettingValue(generalSettings, "only_grades")
                  ? "τάξη"
                  : "μάθημα")
              }
            />
            <div className="classes__list">
              <div
                onClick={() => selectClass(-1)}
                className={
                  "classes__list-item " +
                  (anClass && anClass.length && anClass[0] == [-1]
                    ? "active"
                    : "")
                }
              >
                <span className="class-name">Όλες</span>
              </div>
              {populateClasses()}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* <span className="label">
          Επιλέξτε{" "}
          {getGeneralSettingValue(generalSettings, "only_grades")
            ? "τάξη"
            : "μάθημα"}
        </span>
        <div className="classes section__input" ref={classesContainerRef}>
          <input
            className="input"
            value={searchClass}
            onChange={(e) => setSearchClass(e.target.value)}
            placeholder={
              "Αναζητήστε " +
              (getGeneralSettingValue(generalSettings, "only_grades")
                ? "τάξη"
                : "μάθημα")
            }
          />
          <div className="classes__list">{populateClasses()}</div>
        </div> */}
      </div>
      <div className="right">
        {(getGeneralSettingValue(generalSettings, "only_grades") &&
          eventClass != -1) ||
        (!getGeneralSettingValue(generalSettings, "only_grades") &&
          JSON.stringify(anClass) != "[-1]" &&
          anClass.length == 1) ? (
          <div className="departments section__input">
            <span className="label">
              {eventType === "lecture" ? "Επιλέξτε Τμήμα" : "Επιλέξτε Τμήματα"}
              {showDepartmentError ? (
                <span className="error-msg">Επιλέξτε τμήμα</span>
              ) : (
                ""
              )}
            </span>
            <div className="departments__list custom-horizontal-scrollbar">
              {eventType !== "lecture" ? (
                <div
                  className={"item " + (eventDepartments == -1 ? "active" : "")}
                  onClick={() => setEventDepartments([-1])}
                >
                  Όλα
                </div>
              ) : (
                ""
              )}
              {populateDepartments()}
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="target input-container">
          <span className="label">Ειδοποιήστε:</span>
          <div className="target__select">
            <div
              className="item"
              onClick={() => setForProfessors(!forProfessors)}
            >
              <div
                className={
                  "target__select-radio " + (forProfessors ? "active" : "")
                }
              ></div>
              <span>Καθηγητές</span>
            </div>
            <div className="item" onClick={() => setForStudents(!forStudents)}>
              <div
                className={
                  "target__select-radio " + (forStudents ? "active" : "")
                }
              ></div>
              <span>Μαθητές</span>
            </div>
            <div className="item" onClick={() => setForParents(!forParents)}>
              <div
                className={
                  "target__select-radio " + (forParents ? "active" : "")
                }
              ></div>
              <span>Γονείς</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnnouncementSection;
