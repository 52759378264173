import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import {
  BiCalendar,
  BiHome,
  BiX,
  BiBookOpen,
  BiTime,
  BiDesktop,
} from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import "./parent-event-card.scss";
import StudentExamScore from "../../../StudentExamScore/studentExamScore";
import { useSelector } from "react-redux";
import ParentEvent from "../../ParentEvent/parentEvent";

function ParentEventCard({ event, closeCard }) {
  const socketContext = useContext(SocketContext);

  const children = useSelector((state) => state.profile.children);
  const profile = useSelector((state) => state.profile.value);

  console.log(event);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("el-GR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formatter.format(date);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return formatter.format(date);
  };

  const populateClassrooms = () => {
    return event.classroom_names.map((classroom, index) => {
      return <span key={"classroomName" + index}>{classroom}</span>;
    });
  };

  const startCall = () => {
    if (event.call_link) {
      // if (profile.paid_lectures) {
      //   let joinedLectures = profile.joined_lectures;
      //   if (!joinedLectures) {
      //     joinedLectures = 1;
      //   } else {
      //     joinedLectures++;
      //   }
      //   let body = {
      //     user_id: profile.user_id,
      //     event_id: event.event_id,
      //     date: formatDate(new Date()),
      //   };
      //   socketContext.socket.emit("updateJoinedEvents", body);
      // }

      const isRunningInWebView = () => {
        return window.ReactNativeWebView !== undefined;
      };
      let callLink = event.call_link;
      if (isRunningInWebView && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "OPEN_LINK",
            data: callLink,
          })
        );
      } else {
        window.open(callLink, "_blank");
      }
    }
  };

  return (
    <div className="parent-event-card">
      <div className="parent-event-card__close" onClick={() => closeCard()}>
        <BiX
          size={"30px"}
          color={"#cccccc"}
          // color={"#fff"}
          style={iconStyle("transparent")}
        />
      </div>
      <div className="info">
        <span className="info__title">
          <span className="label">{event.title}</span>
        </span>
        <div className="info__wrapper">
          <div className="info__wrapper-details">
            <div className="item">
              <BiCalendar
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                <span className="date">
                  {formatDate(event.start_at)}
                  {event.type == "online-exam"
                    ? " - " + formatDate(event.finish_at)
                    : ""}
                </span>
              </div>
            </div>
            <div className="item">
              <BiTime
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                <span className="time">
                  {formatTime(event.start_at)} - {formatTime(event.finish_at)}
                </span>
              </div>
            </div>
            <div className="item">
              <BiBookOpen
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                {/* <span className="sublabel">Τύπος εξέτασης: </span> */}
                <span className="label">
                  {event.type == "exam" ? "Διαγώνισμα" : ""}
                  {event.type == "online-exam" ? "Ηλεκτρονικό Διαγώνισμα" : ""}
                  {event.type == "test" ? "Τεστ" : ""}
                </span>
              </div>
            </div>
            {event.type == "exam" ||
            (event.type == "test" && event.classroom_names) ? (
              <div className="item">
                <BiHome
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">{populateClassrooms()}</div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="details">
        {event.type == "exam" ||
        event.type == "test" ||
        event.type == "online-exam" ? (
          <StudentExamScore event={event} />
        ) : (
          ""
        )}
        {event.type == "parent-event" ? <ParentEvent event={event} /> : ""}
        {event.type != "lecture" ? <StudentExamScore event={event} /> : ""}
        {event.type == "lecture" && event.call_link ? (
          <button
            className="cta start-online mobile"
            onClick={() => startCall()}
          >
            <BiDesktop
              size={"25px"}
              color={"#6225e6"}
              style={iconStyle("transparent")}
            />
            <span>Εκκίνηση ηλεκτρονικού μαθήματος</span>
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ParentEventCard;
