import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import { motion } from "framer-motion";
import "./add-curriculum.scss";
import { useSelector } from "react-redux";
import { BiDockBottom, BiUpload, BiX } from "react-icons/bi";
import {
  getGeneralSettingValue,
  iconStyle,
} from "../../../../utils/generalUtils";
import DepartmentItem from "../Items/departmentItem";
import ChapterItem from "../Items/chapterItem";
import Compress from "compress.js";
import ExamThumbnail from "../../../../components/ExamThumbnail/exam-thumbnail";
import { calls } from "../../../../config/db_config";
import { tokenUtils } from "../../../../utils/token-utils";
import { nanoid } from "nanoid";
import { fileUtils } from "../../../../utils/fileUtils";
import axios from "axios";

function AddCurriculum({
  curriculum,
  setCurriculum,
  closed,
  curriculumToEdit,
  setCurriculumToEdit,
  isExpanded,
  setIsExpanded,
}) {
  const envVars = useSelector((state) => state.profile.environmentVars);
  const hasCameraAccess = useSelector((state) => state.profile.hasCameraAccess);
  const generalSettings = useSelector((state) => state.profile.generalSettings);
  const socketContext = useContext(SocketContext);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);

  const [modalHeight, setModalHeight] = useState(85);
  const [modalWidth, setModalWidth] = useState(80);
  const [modalLeft, setModalLeft] = useState(0.1);
  const [modalTop, setModalTop] = useState(0.075);

  const [title, setTitle] = useState("");
  const [classId, setClassId] = useState();
  const [departmentId, setDepartmentId] = useState([]);
  const [fullDepartments, setFullDepartments] = useState([]);
  const [chapterId, setChapterId] = useState([]);
  const [fullChapters, setFullChapters] = useState([]);
  const [searchClass, setSearchClass] = useState("");
  const [isExercise, setIsExercise] = useState(false);

  const [liveWorkLink, setLiveWorkLink] = useState("");

  const [isLocked, setIsLocked] = useState(false);
  const [disableDownload, setDisableDownload] = useState(false);

  const index = 0;
  const [files, setFiles] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const compress = new Compress();

  const [classes, setClasses] = useState([]);

  useEffect(() => {
    if (!isExpanded) {
      setFiles([]);
      setThumbnails([]);
      setTitle("");
      setChapterId([]);
      setIsExercise(false);
      setCurriculumToEdit();
      if (classes && classes.length) {
        setClassId(classes[0].class_id);
      }
    }
    if (window.innerWidth < 700) {
      if (isExpanded) {
        document.getElementById("main-app").style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    } else {
      if (isExpanded) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  useEffect(() => {
    if (curriculumToEdit) {
      setIsExpanded(true);
      setTitle(curriculumToEdit.title);
      setClassId(curriculumToEdit.class_id);
      setLiveWorkLink(curriculumToEdit.live_work_link);
      setIsLocked(curriculumToEdit.is_locked);
      setDisableDownload(curriculumToEdit.disable_download);
      console.log(curriculumToEdit);
      let depTemp = [];
      let filesTemp = [];
      let chapterTemp = [];
      try {
        depTemp = JSON.parse(curriculumToEdit.departments);
        chapterTemp = JSON.parse(curriculumToEdit.chapter_id);
        filesTemp = JSON.parse(curriculumToEdit.files);
      } catch (e) {
        console.log(e);
      }
      try {
        depTemp = JSON.parse(curriculumToEdit.departments);
      } catch (e) {
        console.log(e);
      }
      try {
        filesTemp = JSON.parse(curriculumToEdit.files);
      } catch (e) {
        console.log(e);
      }
      console.log(" temp files ");
      console.log(filesTemp);

      const fetchFilesAndThumbnails = async () => {
        let tempFiles = [];
        let tempThumbnails = [];

        for (let i = 0; i < filesTemp.length; i++) {
          tempFiles.push([]);
          for (let j = 0; j < filesTemp[i].length; j++) {
            const response = await fetch(filesTemp[i][j]);
            const blob = await response.blob();
            const fileName = filesTemp[i][j].split("/").pop();
            const file = new File([blob], fileName, { type: blob.type });
            tempFiles[i].push(file);

            if (blob.type.startsWith("image/")) {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                tempThumbnails.push(reader.result);
                setThumbnails([...tempThumbnails]); // Update thumbnails state
              };
            } else if (blob.type === "application/pdf") {
              tempThumbnails.push("../../../resources/icons/pdf-icon.png");
              setThumbnails([...tempThumbnails]);
            } else if (blob.type.startsWith("audio/")) {
              tempThumbnails.push("../../../resources/icons/audio-icon.png");
              setThumbnails([...tempThumbnails]);
            } else if (
              blob.type === "application/vnd.ms-powerpoint" ||
              blob.type ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation"
            ) {
              tempThumbnails.push("../../../resources/icons/ppt-icon.png");
              setThumbnails([...tempThumbnails]);
            } else if (blob.type.startsWith("video/")) {
              tempThumbnails.push("../../../resources/icons/video-icon.png");
              setThumbnails([...tempThumbnails]);
            }
          }
        }

        setFiles(tempFiles); // Update files state
      };

      fetchFilesAndThumbnails();

      setDepartmentId(depTemp);
      setChapterId(chapterTemp);
    }
  }, [curriculumToEdit]);

  useEffect(() => {
    if (window.innerWidth < 800) {
      setModalHeight(93);
      setModalWidth(95);
      setModalTop(0.05);
      setModalLeft(0.025);
    } else {
      setModalHeight(90);
      setModalWidth(60);
      setModalTop(0.05);
      setModalLeft(0.2);
    }
  }, []);

  useEffect(() => {
    const cleanUpGetClasses = getClasses();
    return () => {
      cleanUpGetClasses();
    };
  }, [searchClass]);

  useEffect(() => {
    if (classId && classId != -1) {
      const cleanUpGetDepartments = getDepartments();
      const cleanUpGetChapters = getChapters();
      return () => {
        cleanUpGetDepartments();
        cleanUpGetChapters();
      };
    }
  }, [classId]);

  const openCard = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newTopPosition = window.innerHeight * modalTop - topPosition;
      let newLeftPosition = window.innerWidth * modalLeft - leftPosition;
      const newPositionStyle = {
        top: newTopPosition + "px",
        left: newLeftPosition + "px",
        width: modalWidth + "vw",
        // height: modalHeight + "vh",
      };
      if (modalHeight) {
        newPositionStyle.height = modalHeight + "vh";
      } else {
        newPositionStyle.height = "auto";
      }
      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
  }, [isExpanded]);

  const getClasses = () => {
    const unique_id = "curriculum-classes";
    let args = { search: searchClass, unique_id: unique_id };

    const getClassesListener = (data) => {
      setClasses(data);
      if (data && data[0]) {
        setClassId(data[0].class_id);
      }
    };

    const refreshClassesListener = () => {
      socketContext.socket.emit("getClasses", args);
    };

    socketContext.socket.on("classes" + unique_id, getClassesListener);
    socketContext.socket.emit("getClasses", args);
    socketContext.socket.on("refreshClasses", refreshClassesListener);

    return () => {
      socketContext.socket.off("getClasses", getClassesListener);
      socketContext.socket.off("classes" + unique_id, getClassesListener);
      socketContext.socket.off("refreshClasses", refreshClassesListener);
    };
  };

  const getDepartments = () => {
    let args = { class_id: classId };

    const getDepartmentsListener = (data) => {
      setFullDepartments(data);
    };

    const refreshDepartmentsListener = () => {
      socketContext.socket.emit("getDepartments", args);
    };

    socketContext.socket.on("departments", getDepartmentsListener);
    socketContext.socket.emit("getDepartments", args);
    socketContext.socket.on("refreshDepartments", refreshDepartmentsListener);

    return () => {
      socketContext.socket.off("getDepartments", getDepartmentsListener);
      socketContext.socket.off("departments", getDepartmentsListener);
      socketContext.socket.off(
        "refreshDepartments",
        refreshDepartmentsListener
      );
    };
  };

  const getChapters = () => {
    let args = { class_id: classId };

    const getChaptersListener = (data) => {
      setFullChapters(data);
    };

    const refreshChaptersListener = () => {
      socketContext.socket.emit("getChapters", args);
    };

    socketContext.socket.on("chapters", getChaptersListener);
    socketContext.socket.emit("getChapters", args);
    socketContext.socket.on("refreshChapters", refreshChaptersListener);

    return () => {
      socketContext.socket.off("getChapters", getChaptersListener);
      socketContext.socket.off("chapters", getChaptersListener);
      socketContext.socket.off("refreshChapters", refreshChaptersListener);
    };
  };

  const populateClasses = () => {
    return classes.map((classItem, i) => {
      return (
        <div
          key={"announcementClassItem" + i}
          onClick={() => setClassId(classItem.class_id)}
          className={
            "classes__list-item " +
            (classId == classItem.class_id ? "active" : "")
          }
        >
          <span className="class-name">{classItem.class_name}</span>
          <span className="grade">{classItem.grade_name}</span>
        </div>
      );
    });
  };

  const populateDepartments = () => {
    if (fullDepartments.length > 0) {
      return fullDepartments.map((department) => {
        return (
          <DepartmentItem
            key={"departmentItem" + department.department_id}
            department={department}
            setSelectedDepartments={setDepartmentId}
            selectDepartments={departmentId}
          ></DepartmentItem>
        );
      });
    } else {
      return (
        <span className="configure__message">
          Επιλέξτε μάθημα για να εμφανιστούν τμήματα
        </span>
      );
    }
  };

  const populateChapters = () => {
    if (fullChapters.length > 0) {
      return fullChapters.map((chapter) => {
        return (
          <ChapterItem
            key={"chapterItem" + chapter.id}
            chapter={chapter}
            setSelectedChapters={setChapterId}
            selectedChapters={chapterId}
          ></ChapterItem>
        );
      });
    } else {
      return (
        <span className="configure__message">
          Επιλέξτε μάθημα για να εμφανιστούν κεφάλαια
        </span>
      );
    }
  };

  const imageChange = async (event) => {
    let fileList = Array.from(event.target.files);

    const imageList = fileList.filter(
      (file) =>
        file.type !== "application/pdf" &&
        !file.type.startsWith("audio/") &&
        !file.type.startsWith("video/") &&
        !file.type.startsWith("application/vnd.ms-powerpoint") &&
        !file.type.startsWith(
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        )
    );
    const pdfList = fileList.filter((file) => file.type === "application/pdf");
    const audioList = fileList.filter((file) => file.type.startsWith("audio/"));
    const videoList = fileList.filter((file) => file.type.startsWith("video/"));
    const pptList = fileList.filter(
      (file) =>
        file.type === "application/vnd.ms-powerpoint" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    );

    let tempThumbnailList = [];
    let updatedFileList = files;
    updatedFileList[index] = [];

    await compress
      .compress(imageList, {
        size: 2, // the max size in MB, defaults to 2MB
        quality: 0.75, // the quality of the image, max is 1,
        maxWidth: 1920, // the max width of the output image, defaults to 1920px
        maxHeight: 1920, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
        rotate: false, //rotation section below
      })
      .then((data) => {
        data.forEach(function (singleData) {
          const base64 = singleData.data;
          const name = singleData.alt;
          const imgExt = singleData.ext;

          const blob = Compress.convertBase64ToFile(base64, imgExt);
          let file = new File([blob], name);
          updatedFileList[index].push(file);

          let baseData = "data:" + imgExt + ";base64," + base64;
          tempThumbnailList.push(baseData);
        });
      });

    pdfList.forEach((pdfFile) => {
      updatedFileList[index].push(pdfFile);
      tempThumbnailList.push("../../../resources/icons/pdf-icon.png");
    });

    audioList.forEach((audioFile) => {
      updatedFileList[index].push(audioFile);
      tempThumbnailList.push("../../../resources/icons/audio-icon.png");
    });

    videoList.forEach((videoFile) => {
      updatedFileList[index].push(videoFile);
      tempThumbnailList.push("../../../resources/icons/video-icon.png");
    });

    pptList.forEach((pptFile) => {
      updatedFileList[index].push(pptFile);
      tempThumbnailList.push("../../../resources/icons/ppt-icon.png");
    });

    setThumbnails(tempThumbnailList);
    setFiles(updatedFileList);
  };

  const populateThumbnails = () => {
    if (!files || !files[index]) {
      return null;
    }

    return files[index].map((file, i) => {
      let imageSrc = "";
      if (file.type === "application/pdf") {
        imageSrc = "../../../resources/icons/pdf-icon.png";
      } else if (file.type.startsWith("audio/")) {
        imageSrc = "../../../resources/icons/audio-icon.png";
      } else if (file.type.startsWith("video/")) {
        imageSrc = "../../../resources/icons/video-icon.png";
      } else if (
        file.type === "application/vnd.ms-powerpoint" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        imageSrc = "../../../resources/icons/ppt-icon.png";
      } else {
        imageSrc = thumbnails[i];
      }

      return (
        <ExamThumbnail
          key={i}
          index={i}
          removeThumbnail={removeThumbnail}
          image={imageSrc}
        />
      );
    });
  };

  const removeThumbnail = (i) => {
    let updatedFileList = files;
    let tempThumbnails = thumbnails;

    if (thumbnails.length >= 1) {
      updatedFileList[index].splice(i, 1);
      tempThumbnails.splice(i, 1);
    } else {
      updatedFileList[index] = [];
      tempThumbnails = [];
    }

    setThumbnails(Array.from(tempThumbnails));
    setFiles(updatedFileList);
  };

  const saveCurriculum = () => {
    let submissionLinks = [];
    const newCurriculum = {
      curriculum_id: -1,
      title: title,
      class_id: classId,
      chapter_id: !chapterId.length ? "" : JSON.stringify(chapterId),
      is_exercise: isExercise,
      departments: !departmentId.length ? "" : JSON.stringify(departmentId),
      class_name: classes.find((cls) => cls.class_id == classId).class_name,
      disable_download: disableDownload,
      department_names:
        departmentId && departmentId
          ? fullDepartments
              .filter((dept) => departmentId.includes(dept.department_id))
              .map((dept) => dept.department_name)
              .join(", ")
          : "",
      chapter_names:
        chapterId && chapterId.length
          ? fullChapters
              .filter((chapt) => chapterId.includes(chapt.id))
              .map((chapt) => chapt.chapter_name)
              .join(", ")
          : "",
    };
    setIsExpanded(false);

    if (!curriculumToEdit) {
      setCurriculum([newCurriculum, ...curriculum]);
    }

    const formData = new FormData();
    let i = 0;
    if (files.length) {
      files.forEach((file) => {
        let j = 0;
        submissionLinks.push([]);
        file.forEach((image) => {
          let blob = image.slice(0, image.size);
          let name = `exam-${i}-${j++}-${nanoid()}.${fileUtils.getFileExtension(
            image.name
          )}`;
          let newFile = new File([blob], name, { type: image.type });

          let link =
            calls.endpoint +
            `/resources/${envVars.DB_SCHOOL_NAME}/curriculum/` +
            name;
          submissionLinks[i].push(link);

          formData.append("arrayOfFilesName", newFile);
        });
        i++;
      });

      try {
        axios
          .post(calls.submitCurriculumImages, formData, {
            headers: { Authorization: tokenUtils.getBearerToken() },
          })
          .then((response) => {
            if (response.data) {
              if (!curriculumToEdit) {
                const eventBody = {
                  submissionLinks: submissionLinks,
                  title: title,
                  class_id: classId,
                  chapter_id: !chapterId.length
                    ? ""
                    : JSON.stringify(chapterId),
                  is_exercise: isExercise,
                  departments: !departmentId.length
                    ? ""
                    : JSON.stringify(departmentId),
                  is_locked: isLocked,
                  live_work_link: liveWorkLink,
                  disable_download: disableDownload,
                };

                socketContext.socket.emit("uploadCurriculum", eventBody);
              } else {
                const eventBody = {
                  curriculum_id: curriculumToEdit.id,
                  submissionLinks: submissionLinks,
                  title: title,
                  class_id: classId,
                  chapter_id: !chapterId.length
                    ? ""
                    : JSON.stringify(chapterId),
                  is_exercise: isExercise,
                  departments: !departmentId.length
                    ? ""
                    : JSON.stringify(departmentId),
                  is_locked: isLocked,
                  live_work_link: liveWorkLink,
                  disable_download: disableDownload,
                };

                socketContext.socket.emit("updateCurriculum", eventBody);
              }
              setFiles([]);
              setThumbnails([]);
              setIsExpanded(false);
              document.body.style.overflowY = "auto";
            }
          });
      } catch (ex) {
        console.log(ex);
      }
    } else {
      if (curriculumToEdit) {
        const eventBody = {
          title: title,
          curriculum_id: curriculumToEdit.id,
          class_id: classId,
          chapter_id: !chapterId.length ? "" : JSON.stringify(chapterId),
          is_exercise: isExercise,
          departments: !departmentId.length ? "" : JSON.stringify(departmentId),
          is_locked: isLocked,
          live_work_link: liveWorkLink,
          disable_download: disableDownload,
        };
        socketContext.socket.emit("updateCurriculum", eventBody);
      } else {
        const eventBody = {
          title: title,
          class_id: classId,
          chapter_id: !chapterId.length ? "" : JSON.stringify(chapterId),
          is_exercise: isExercise,
          departments: !departmentId.length ? "" : JSON.stringify(departmentId),
          is_locked: isLocked,
          live_work_link: liveWorkLink,
          disable_download: disableDownload,
        };
        socketContext.socket.emit("uploadCurriculum", eventBody);
      }
      setDepartmentId([]);
      setChapterId([]);
      setFiles([]);
      setThumbnails([]);
      document.body.style.overflowY = "auto";
    }
  };

  return (
    <div
      ref={containerRef}
      className={
        "add-curriculum " +
        (isExpanded ? " is-expanded " : " is-collapsed  ") +
        (closed ? " closed" : " open")
      }
    >
      {isExpanded ? (
        <div
          onClick={() => {
            setIsExpanded(false);
          }}
          className={"modal-background show "}
        ></div>
      ) : (
        ""
      )}
      <motion.div
        initial={false}
        className={
          "add-curriculum__content " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        {isExpanded ? (
          <div className="add-curriculum__config no-scrollbar">
            <div
              className="add-curriculum__config-close"
              onClick={() => setIsExpanded(false)}
            >
              <BiX
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("#f2f2f2")}
              />
            </div>
            <div className="add-curriculum__config-item">
              <span className="label">Τίτλος</span>
              <input
                className="input"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Τίτλος Ύλης"
              />
            </div>
            <div className="add-curriculum__config-item">
              <span className="label">Επιλέξτε Μάθημα</span>
              <div className="classes section__input">
                <input
                  className="input"
                  value={searchClass}
                  onChange={(e) => setSearchClass(e.target.value)}
                  placeholder="Αναζητήστε μάθημα"
                />
                <div className="classes__list">{populateClasses()}</div>
              </div>
            </div>
            <div className="add-curriculum__config-item">
              <span className="label">Επιλέξτε Τμήματα</span>
              <div className="departments">
                <div className="departments__list">{populateDepartments()}</div>
              </div>
            </div>
            <div className="add-curriculum__config-item">
              <span className="label">Επιλέξτε Κεφάλαια</span>
              <div className="departments">
                <div className="departments__list">{populateChapters()}</div>
              </div>
            </div>
            {getGeneralSettingValue(generalSettings, "has-live-work") ? (
              <div className="add-curriculum__config-item">
                <span className="label">Ενσωμάτωση Live Work</span>
                <input
                  className="input"
                  value={liveWorkLink}
                  onChange={(e) => setLiveWorkLink(e.target.value)}
                  placeholder="Live Work URL"
                />
              </div>
            ) : (
              ""
            )}
            <div className="add-curriculum__config-item">
              <span className="label">Ανέβασμα Αρχείων</span>
              <div className="add-curriculum__config-item-files">
                {hasCameraAccess !== 0 && (
                  <div className="add-curriculum__config-item-upload">
                    <BiUpload
                      size={"50px"}
                      color={"#ccc"}
                      style={iconStyle("transparent")}
                    />
                    <input
                      className="form-control configure__files-input"
                      type="file"
                      accept=".pdf, .jpeg, .jpg, .png, .mp3, .wav, .mp4, .ppt, .pptx"
                      name="myImage"
                      onChange={imageChange}
                      multiple
                    />
                  </div>
                )}
                {!hasCameraAccess && (
                  <span className="configure__files-error">
                    Ενεργοποιήστε τα δικαιώματα κάμερας για να έχετε πρόσβαση
                    στην κάμερα και στα αρχεία σας
                  </span>
                )}
                <div className="add-curriculum__config-item-thumbnails">
                  {populateThumbnails()}
                </div>
              </div>
            </div>

            <div className="add-curriculum__config-item">
              <div
                className="configure__accept-answers checkbox-wrapper"
                onClick={() => setIsExercise(!isExercise)}
              >
                <div
                  className={"checkbox " + (isExercise ? "active" : "")}
                ></div>
                <span className="ms-2">
                  Αποδοχή απαντήσεων απο τους μαθητές
                </span>
              </div>
            </div>
            <div className="add-curriculum__config-item">
              <div
                className="configure__accept-answers checkbox-wrapper"
                onClick={() => setIsLocked(!isLocked)}
              >
                <div className={"checkbox " + (isLocked ? "active" : "")}></div>
                <span className="ms-2">Κλειδωμένο</span>
              </div>
            </div>
            <div className="add-curriculum__config-item">
              <div
                className="configure__accept-answers checkbox-wrapper"
                onClick={() => setDisableDownload(!disableDownload)}
              >
                <div
                  className={"checkbox " + (disableDownload ? "active" : "")}
                ></div>
                <span className="ms-2">Απενεργοποίηση Λήψης Αρχείων</span>
              </div>
            </div>
            <div className="add-curriculum__config-save">
              <button className="cta" onClick={() => saveCurriculum()}>
                Αποθήκευση
              </button>
            </div>
          </div>
        ) : (
          <div className="add-curriculum__preview">
            <BiDockBottom
              size={"30px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
            <span>{curriculumToEdit ? "Αποθήκευση" : "Ανέβασμα Ύλης"}</span>
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default AddCurriculum;
