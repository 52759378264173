import React, { useEffect, useState, useContext } from "react";
import Slider from "react-slick";
import { Bar } from "react-chartjs-2";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./weekly-slider.scss";
import { Link } from "react-router-dom";
import { iconStyle } from "../../../../utils/generalUtils";
import { BiPrinter } from "react-icons/bi";
import { SocketContext } from "../../../../app/socket";

const getWeekNumber = (date) => {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - startOfYear) / 86400000;

  return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
};

const getStartOfWeek = (date) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
  const startOfWeek = new Date(date.setDate(diff));
  startOfWeek.setHours(0, 0, 0, 0); // Start of the day
  return startOfWeek;
};

const filterTuitionByDay = (studentTuition, date) => {
  return studentTuition.filter((tuition) => {
    const tuitionDate = new Date(tuition.time.replace(" ", "T")).toDateString();
    return tuitionDate === date.toDateString();
  });
};

const filterTuitionByWeek = (studentTuition, startOfWeek, endOfWeek) => {
  return studentTuition.filter((tuition) => {
    const tuitionDate = new Date(tuition.time.replace(" ", "T"));
    tuitionDate.setHours(0, 0, 0, 0); // Ensure we match full days only
    return tuitionDate >= startOfWeek && tuitionDate <= endOfWeek;
  });
};

const WeeklySlider = ({ studentTuition, users }) => {
  const socketContext = useContext(SocketContext);
  const currentWeekNumber = getWeekNumber(new Date());
  const [currentWeek, setCurrentWeek] = useState(currentWeekNumber);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [priceList, setPriceList] = useState([]);
  const [generalTuition, setGeneralTuition] = useState([]);

  useEffect(() => {
    const cleanUpGetPriceList = getPriceList();
    const cleanUpGetStudentTuition = getStudentTuition();
    return () => {
      cleanUpGetPriceList();
      cleanUpGetStudentTuition();
    };
  }, []);

  const getStudentTuition = () => {
    let args = {
      user_id: -1,
    };

    const getStudentTuitionListener = (data) => {
      setGeneralTuition(data);
    };

    const refreshStudentTuitionListener = () => {
      socketContext.socket.emit("getStudentTuition", args);
    };

    socketContext.socket.on("studentTuition-1", getStudentTuitionListener);
    socketContext.socket.emit("getStudentTuition", args);
    socketContext.socket.on(
      "refreshStudentTuition-1",
      refreshStudentTuitionListener
    );

    return () => {
      socketContext.socket.off("getStudentTuition", getStudentTuitionListener);
      socketContext.socket.off("studentTuition-1", getStudentTuitionListener);
      socketContext.socket.off(
        "refreshStudentTuition-1",
        refreshStudentTuitionListener
      );
    };
  };

  const getPriceList = () => {
    let args = {};

    const getPriceListListener = (data) => {
      setPriceList(data);
    };

    const refreshPriceListListener = () => {
      console.log(" refreshing price list ");
      socketContext.socket.emit("getPriceList", args);
    };

    socketContext.socket.on("priceList", getPriceListListener);
    socketContext.socket.emit("getPriceList", args);
    socketContext.socket.on("refreshPriceList", refreshPriceListListener);

    return () => {
      socketContext.socket.off("getPriceList", getPriceListListener);
      socketContext.socket.off("priceList", getPriceListListener);
      socketContext.socket.off("refreshPriceList", refreshPriceListListener);
    };
  };

  const handleWeekChange = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + direction * 7);
    setCurrentDate(newDate);
    setCurrentWeek(getWeekNumber(newDate));
  };

  const handleWeekSelect = (week) => {
    const date = new Date(new Date().getFullYear(), 0, (week - 1) * 7 + 1);
    setCurrentDate(getStartOfWeek(date));
    setCurrentWeek(week);
    setIsDropdownOpen(false);
  };

  const renderChart = (startOfWeek) => {
    const daysOfWeek = Array.from({ length: 7 }).map((_, index) => {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + index);
      return day;
    });

    const data = {
      labels: daysOfWeek.map((day) =>
        day.toLocaleDateString("el-GR", { weekday: "long", day: "numeric" })
      ),
      datasets: [
        {
          label: "Σύνολο Δίδακτρα (€)",
          data: daysOfWeek.map((day) => {
            const tuitionForDay = filterTuitionByDay(studentTuition, day);
            return tuitionForDay.reduce((sum, item) => sum + item.amount, 0);
          }),
          backgroundColor: "#6124e681",
          borderRadius: 10,
        },
      ],
    };

    return <Bar data={data} />;
  };

  const renderUsers = (startOfWeek, endOfWeek) => {
    // Get all the tuition payments for the week
    const tuitionForWeek = filterTuitionByWeek(
      studentTuition,
      startOfWeek,
      endOfWeek
    );

    // Combine all tuition payments with user info
    const combinedTuition = tuitionForWeek.map((tuition) => {
      const user = users.find((user) => user.user_id === tuition.student_id);
      return { ...tuition, user }; // Attach user info to each tuition entry
    });

    // Sort the combined tuition payments by date and time
    const sortedTuition = combinedTuition.sort((a, b) => {
      const dateA = new Date(a.time.replace(" ", "T"));
      const dateB = new Date(b.time.replace(" ", "T"));
      return dateA - dateB;
    });

    // Render each payment individually
    return sortedTuition.map((tuition, index) => {
      const paymentDate = new Date(tuition.time.replace(" ", "T"));
      const formattedDate = `${paymentDate.getDate()}/${
        paymentDate.getMonth() + 1
      }/${paymentDate.getFullYear()}`;

      // Ensure user is attached to the tuition data
      const user = tuition.user || { first_name: "", last_name: "" };

      // Determine payment method in Greek
      let paymentMethod = "";
      if (tuition.payment_method === "cash") {
        paymentMethod = "Μετρητά";
      } else if (tuition.payment_method === "bank") {
        paymentMethod = "Τράπεζα";
      } else if (tuition.payment_method === "card") {
        paymentMethod = "Κάρτα";
      }

      // Determine tuition title
      let tuitionTitle = "Δίδακτρα";
      if (tuition.tuition_id && tuition.tuition_id !== -1) {
        let tuitionItem = generalTuition.find(
          (tui) => tui.student_tuition_id === tuition.tuition_id
        );

        if (tuitionItem) {
          let priceListItem = priceList.find(
            (prc) => prc.price_list_id === tuitionItem.price_list_id
          );
          if (priceListItem) {
            tuitionTitle = priceListItem.title;
          }
        }
      }

      return (
        <Link
          to={"/profile?user-id=" + user.user_id}
          key={index}
          className="user-item"
        >
          <div className="details">
            <img
              className="details__img"
              src={
                user.profile_picture
                  ? user.profile_picture
                  : "resources/student.png"
              }
              alt={"student"}
            />
            <span className="name">
              {user.first_name} {user.last_name}
            </span>
          </div>
          <span className="amount">
            {tuition.amount} € - {paymentMethod} - {formattedDate}
          </span>
          <span className="tuition-title">{tuitionTitle}</span>
        </Link>
      );
    });
  };

  const startOfWeek = getStartOfWeek(new Date(currentDate));
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  endOfWeek.setHours(23, 59, 59, 999); // End of the day
  const headerText =
    startOfWeek.getMonth() === endOfWeek.getMonth()
      ? `${startOfWeek.getDate()} - ${endOfWeek.getDate()} ${startOfWeek.toLocaleDateString(
          "el-GR",
          { month: "long" }
        )}`
      : `${startOfWeek.getDate()} ${startOfWeek.toLocaleDateString("el-GR", {
          month: "long",
        })} - ${endOfWeek.getDate()} ${endOfWeek.toLocaleDateString("el-GR", {
          month: "long",
        })}`;

  const renderDropdown = () => {
    const weeks = Array.from({ length: 52 }).map((_, index) => index + 1);

    return (
      <div className={`dropdown ${isDropdownOpen ? "open" : ""}`}>
        {weeks.map((week) => {
          const startDate = getStartOfWeek(
            new Date(new Date().getFullYear(), 0, (week - 1) * 7 + 1)
          );
          const endDate = new Date(startDate);
          endDate.setDate(startDate.getDate() + 6);

          return (
            <div
              key={week}
              className={`dropdown-item ${
                week === currentWeek ? "selected" : ""
              }`}
              onClick={() => handleWeekSelect(week)}
            >
              <span>
                {`${startDate.getDate()} ${startDate.toLocaleDateString(
                  "el-GR",
                  {
                    month: "short",
                  }
                )} - ${endDate.getDate()} ${endDate.toLocaleDateString(
                  "el-GR",
                  {
                    month: "short",
                  }
                )}`}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const generateUserRowsForDay = (dayOfWeek) => {
    const startOfWeek = getStartOfWeek(currentDate);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    // Get all the tuition payments for the week
    const tuitionForWeek = filterTuitionByWeek(
      studentTuition,
      startOfWeek,
      endOfWeek
    );

    // Combine all tuition payments for all users into one array
    const combinedTuition = tuitionForWeek.map((tuition) => {
      const user = users.find((user) => user.user_id === tuition.student_id);
      return { ...tuition, user }; // Attach user info to each tuition entry
    });

    // Adjust JavaScript's getDay() to make 0 represent Monday instead of Sunday
    const adjustedDayOfWeek = (dayOfWeek + 1) % 7; // Shift dayOfWeek so 0 becomes Monday, 6 becomes Sunday

    // Filter the combined tuition to include only payments for the specific day of the week
    const filteredByDay = combinedTuition.filter((tuition) => {
      const paymentDate = new Date(tuition.time.replace(" ", "T"));
      return paymentDate.getDay() === adjustedDayOfWeek; // Match the specific day of the week
    });

    // Sort the filtered tuition payments by date and time
    const sortedTuition = filteredByDay.sort((a, b) => {
      const dateA = new Date(a.time.replace(" ", "T"));
      const dateB = new Date(b.time.replace(" ", "T"));
      return dateA - dateB;
    });

    // Generate the table rows from the sorted tuition payments
    return sortedTuition
      .map((tuition) => {
        const paymentDate = new Date(tuition.time.replace(" ", "T"));
        const formattedDate = `${paymentDate.getDate()}/${
          paymentDate.getMonth() + 1
        }/${paymentDate.getFullYear()} ${paymentDate.getHours()}:${paymentDate.getMinutes()}`;

        let paymentMethod = "";
        if (tuition.payment_method === "cash") {
          paymentMethod = "Μετρητά";
        } else if (tuition.payment_method === "bank") {
          paymentMethod = "Τράπεζα";
        } else if (tuition.payment_method === "card") {
          paymentMethod = "Κάρτα";
        }

        let tuitionTitle = "Δίδακτρα";
        if (tuition.tuition_id && tuition.tuition_id !== -1) {
          let tuitionItem = generalTuition.find(
            (tui) => tui.student_tuition_id === tuition.tuition_id
          );

          if (tuitionItem) {
            let priceListItem = priceList.find(
              (prc) => prc.price_list_id === tuitionItem.price_list_id
            );
            if (priceListItem) {
              tuitionTitle = priceListItem.title;
            }
          }
        }

        // Ensure user is attached to the tuition data
        const user = tuition.user || { first_name: "", last_name: "" };

        return `
        <tr>
          <td width="20%" style="padding: 10px; word-break: break-word; border-top: 3px solid #dddddd; border-right: 3px solid #dddddd; border-bottom: 3px solid #dddddd; border-left: 3px solid #dddddd;">
            ${user.first_name} ${user.last_name}
          </td>
          <td width="20%" style="padding: 10px; word-break: break-word; border-top: 3px solid #dddddd; border-right: 3px solid #dddddd; border-bottom: 3px solid #dddddd; border-left: 3px solid #dddddd;">
            ${tuitionTitle}
          </td>
          <td width="20%" style="padding: 10px; word-break: break-word; border-top: 3px solid #dddddd; border-right: 3px solid #dddddd; border-bottom: 3px solid #dddddd; border-left: 3px solid #dddddd;">
            ${paymentMethod}
          </td>
          <td width="20%" style="padding: 10px; word-break: break-word; border-top: 3px solid #dddddd; border-right: 3px solid #dddddd; border-bottom: 3px solid #dddddd; border-left: 3px solid #dddddd;">
            ${formattedDate}
          </td>
          <td width="20%" style="padding: 10px; word-break: break-word; border-top: 3px solid #dddddd; border-right: 3px solid #dddddd; border-bottom: 3px solid #dddddd; border-left: 3px solid #dddddd;">
            ${tuition.amount} €
          </td>
        </tr>
      `;
      })
      .join(""); // Join all rows into a single string
  };

  const generateUserRows = () => {
    const startOfWeek = getStartOfWeek(currentDate);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    // Get all the tuition payments for the week
    const tuitionForWeek = filterTuitionByWeek(
      studentTuition,
      startOfWeek,
      endOfWeek
    );

    // Combine all tuition payments for all users into one array
    const combinedTuition = tuitionForWeek.map((tuition) => {
      const user = users.find((user) => user.user_id === tuition.student_id);
      return { ...tuition, user }; // Attach user info to each tuition entry
    });

    // Sort the combined tuition payments by date and time
    const sortedTuition = combinedTuition.sort((a, b) => {
      const dateA = new Date(a.time.replace(" ", "T"));
      const dateB = new Date(b.time.replace(" ", "T"));
      return dateA - dateB;
    });

    // Generate the table rows from the sorted tuition payments
    return sortedTuition
      .map((tuition) => {
        const paymentDate = new Date(tuition.time.replace(" ", "T"));
        const formattedDate = `${paymentDate.getDate()}/${
          paymentDate.getMonth() + 1
        }/${paymentDate.getFullYear()} ${paymentDate.getHours()}:${paymentDate.getMinutes()}`;

        let paymentMethod = "";
        if (tuition.payment_method === "cash") {
          paymentMethod = "Μετρητά";
        } else if (tuition.payment_method === "bank") {
          paymentMethod = "Τράπεζα";
        } else if (tuition.payment_method === "card") {
          paymentMethod = "Κάρτα";
        }

        let tuitionTitle = "Δίδακτρα";
        if (tuition.tuition_id && tuition.tuition_id !== -1) {
          let tuitionItem = generalTuition.find(
            (tui) => tui.student_tuition_id === tuition.tuition_id
          );

          if (tuitionItem) {
            let priceListItem = priceList.find(
              (prc) => prc.price_list_id === tuitionItem.price_list_id
            );
            if (priceListItem) {
              tuitionTitle = priceListItem.title;
            }
          }
        }

        // Ensure user is attached to the tuition data
        const user = tuition.user || { first_name: "", last_name: "" };

        return `
        <tr>
          <td width="20%" style="padding: 10px; word-break: break-word; border-top: 3px solid #dddddd; border-right: 3px solid #dddddd; border-bottom: 3px solid #dddddd; border-left: 3px solid #dddddd;">
            ${user.first_name} ${user.last_name}
          </td>
          <td width="20%" style="padding: 10px; word-break: break-word; border-top: 3px solid #dddddd; border-right: 3px solid #dddddd; border-bottom: 3px solid #dddddd; border-left: 3px solid #dddddd;">
            ${tuitionTitle}
          </td>
          <td width="20%" style="padding: 10px; word-break: break-word; border-top: 3px solid #dddddd; border-right: 3px solid #dddddd; border-bottom: 3px solid #dddddd; border-left: 3px solid #dddddd;">
            ${paymentMethod}
          </td>
          <td width="20%" style="padding: 10px; word-break: break-word; border-top: 3px solid #dddddd; border-right: 3px solid #dddddd; border-bottom: 3px solid #dddddd; border-left: 3px solid #dddddd;">
            ${formattedDate}
          </td>
          <td width="20%" style="padding: 10px; word-break: break-word; border-top: 3px solid #dddddd; border-right: 3px solid #dddddd; border-bottom: 3px solid #dddddd; border-left: 3px solid #dddddd;">
            ${tuition.amount} €
          </td>
        </tr>
      `;
      })
      .join(""); // Join all rows into a single string
  };

  let htmlTemplate = `
  <!DOCTYPE html>
  <html
    xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
    lang="en"
  >
    <head>
      <title></title>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <!--[if mso
        ]><xml
          ><o:OfficeDocumentSettings
            ><o:PixelsPerInch>96</o:PixelsPerInch
            ><o:AllowPNG /></o:OfficeDocumentSettings></xml
      ><![endif]-->
      <!--[if !mso]><!-->
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900"
        rel="stylesheet"
        type="text/css"
      />
      <!--<![endif]-->
      <style>
        * {
          box-sizing: border-box;
        }
  
        body {
          margin: 0;
          padding: 0;
        }
  
        a[x-apple-data-detectors] {
          color: inherit !important;
          text-decoration: inherit !important;
        }
  
        #MessageViewBody a {
          color: inherit;
          text-decoration: none;
        }
  
        p {
          line-height: inherit;
        }
  
        .desktop_hide,
        .desktop_hide table {
          mso-hide: all;
          display: none;
          max-height: 0px;
          overflow: hidden;
        }
  
        .image_block img + div {
          display: none;
        }
  
        sup,
        sub {
          line-height: 0;
          font-size: 75%;
        }
  
        @media (max-width: 768px) {
          .desktop_hide table.icons-inner {
            display: inline-block !important;
          }
  
          .icons-inner {
            text-align: center;
          }
  
          .icons-inner td {
            margin: 0 auto;
          }
  
          .mobile_hide {
            display: none;
          }
  
          .row-content {
            width: 100% !important;
          }
  
          .stack .column {
            width: 100%;
            display: block;
          }
  
          .mobile_hide {
            min-height: 0;
            max-height: 0;
            max-width: 0;
            overflow: hidden;
            font-size: 0px;
          }
  
          .desktop_hide,
          .desktop_hide table {
            display: table !important;
            max-height: none !important;
          }
        }
      </style>
      <!--[if mso
        ]><style>
          sup,
          sub {
            font-size: 100% !important;
          }
          sup {
            mso-text-raise: 10%;
          }
          sub {
            mso-text-raise: -10%;
          }
        </style>
      <![endif]-->
    </head>
  
    <body
      class="body"
      style="
        background-color: #ffffff;
        margin: 0;
        padding: 0;
        -webkit-text-size-adjust: none;
        text-size-adjust: none;
      "
    >
      <table
        class="nl-container"
        width="100%"
        border="0"
        cellpadding="0"
        cellspacing="0"
        role="presentation"
        style="
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          background-color: #ffffff;
        "
      >
        <tbody>
          <tr>
            <td>
              <table
                class="row row-1"
                align="center"
                width="100%"
                border="0"
                cellpadding="0"
                cellspacing="0"
                role="presentation"
                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        class="row-content stack"
                        align="center"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-radius: 0;
                          color: #000000;
                          width: 900px;
                          margin: 0 auto;
                        "
                        width="900"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="column column-1"
                              width="75%"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                font-weight: 400;
                                text-align: left;
                                padding-bottom: 5px;
                                padding-top: 5px;
                                vertical-align: middle;
                                border-top: 0px;
                                border-right: 0px;
                                border-bottom: 0px;
                                border-left: 0px;
                              "
                            >
                              <table
                                class="heading_block block-1"
                                width="100%"
                                border="0"
                                cellpadding="10"
                                cellspacing="0"
                                role="presentation"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                "
                              >
                                <tr>
                                  <td class="pad">
                                    <h1
                                      style="
                                        margin: 0;
                                        color: #7747ff;
                                        direction: ltr;
                                        font-family: 'Montserrat', 'Trebuchet MS',
                                          'Lucida Grande', 'Lucida Sans Unicode',
                                          'Lucida Sans', Tahoma, sans-serif;
                                        font-size: 38px;
                                        font-weight: 700;
                                        letter-spacing: normal;
                                        line-height: 120%;
                                        text-align: center;
                                        margin-top: 0;
                                        margin-bottom: 0;
                                        mso-line-height-alt: 45.6px;
                                      "
                                    >
                                    ${
                                      window.location.hostname.includes(
                                        "oramapaideias"
                                      )
                                        ? `<img src="https://oramapaideias.edupal.gr/resources/logos/orama_paideias.png" style="display: block; height: auto; border: 0; max-width: 135px; width: 100%;" width="135" alt="Logo" title="Logo">`
                                        : `<img src="https://md.edupal.gr/resources/logos/edupal.png" style="display: block; height: auto; border: 0; max-width: 135px; width: 100%;" width="135" alt="Logo" title="Logo">`
                                    }
                                    </h1>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td
                              class="column column-2"
                              width="25%"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                font-weight: 400;
                                text-align: left;
                                padding-bottom: 5px;
                                padding-top: 5px;
                                vertical-align: middle;
                                border-top: 0px;
                                border-right: 0px;
                                border-bottom: 0px;
                                border-left: 0px;
                              "
                            >
                              <table
                                class="paragraph_block block-1"
                                width="100%"
                                border="0"
                                cellpadding="10"
                                cellspacing="0"
                                role="presentation"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                  word-break: break-word;
                                "
                              >
                                <tr>
                                  <td class="pad">
                                    <div
                                      style="
                                        color: #101112;
                                        direction: ltr;
                                        font-family: 'Montserrat', 'Trebuchet MS',
                                          'Lucida Grande', 'Lucida Sans Unicode',
                                          'Lucida Sans', Tahoma, sans-serif;
                                        font-size: 16px;
                                        font-weight: 400;
                                        letter-spacing: 0px;
                                        line-height: 120%;
                                        text-align: center;
                                        mso-line-height-alt: 19.2px;
                                      "
                                    >
                                      <p style="margin: 0; margin-bottom: 16px">
                                        <strong>Οικονομικά δεδομένα</strong>
                                      </p>
                                      <p style="margin: 0">
                                        <strong>#weekPeriod</strong>
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="row row-3"
                align="center"
                width="100%"
                border="0"
                cellpadding="0"
                cellspacing="0"
                role="presentation"
                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        class="row-content stack"
                        align="center"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-radius: 0;
                          color: #000000;
                          width: 900px;
                          margin: 0 auto;
                        "
                        width="900"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="column column-1"
                              width="100%"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                font-weight: 400;
                                text-align: left;
                                padding-bottom: 5px;
                                padding-top: 5px;
                                vertical-align: top;
                                border-top: 0px;
                                border-right: 0px;
                                border-bottom: 0px;
                                border-left: 0px;
                              "
                            >
                              <table
                                class="table_block block-1"
                                width="100%"
                                border="0"
                                cellpadding="10"
                                cellspacing="0"
                                role="presentation"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                "
                              >
                                <tr>
                                  <td class="pad">
                                    <table
                                      style="
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                        border-collapse: collapse;
                                        width: 100%;
                                        table-layout: fixed;
                                        direction: ltr;
                                        background-color: transparent;
                                        font-family: 'Montserrat', 'Trebuchet MS',
                                          'Lucida Grande', 'Lucida Sans Unicode',
                                          'Lucida Sans', Tahoma, sans-serif;
                                        font-weight: 400;
                                        color: #101112;
                                        text-align: center;
                                        letter-spacing: 0px;
                                        word-break: break-all;
                                      "
                                      width="100%"
                                    >
                                      <thead
                                        style="
                                          vertical-align: top;
                                          background-color: #6225e6;
                                          color: #ffffff;
                                          font-size: 14px;
                                          line-height: 120%;
                                          text-align: center;
                                        "
                                      >
                                        <tr>
                                          <th
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΔΕΥΤΕΡΑ
                                          </th>
                                          <th
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΤΡΙΤΗ
                                          </th>
                                          <th
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΤΕΤΑΡΤΗ
                                          </th>
                                          <th
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΠΕΜΠΤΗ
                                          </th>
                                          <th
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΠΑΡΑΣΚΕΥΗ
                                          </th>
                                          <th
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΣΑΒΒΑΤΟ
                                          </th>
                                          <th
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΚΥΡΙΑΚΗ
                                          </th>
                                          <th
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            Σύνολο πληρωμών<br />
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody
                                        style="
                                          vertical-align: top;
                                          font-size: 16px;
                                          line-height: 120%;
                                        "
                                      >
                                        <tr>
                                          <td
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            #mondayammount €&nbsp;<br />
                                          </td>
                                          <td
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            #tuesdayammount €&nbsp;
                                          </td>
                                          <td
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            #wednesdayammount €&nbsp;
                                          </td>
                                          <td
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            #thursdayammount €&nbsp;
                                          </td>
                                          <td
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            #fridayammount €&nbsp;
                                          </td>
                                          <td
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            #saturdayammount €&nbsp;
                                          </td>
                                          <td
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            #sundayammount €&nbsp;
                                          </td>
                                          <td
                                            width="12.5%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            #totalammount €&nbsp;
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="row row-2"
                align="center"
                width="100%"
                border="0"
                cellpadding="0"
                cellspacing="0"
                role="presentation"
                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        class="row-content stack"
                        align="center"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        role="presentation"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          border-radius: 0;
                          color: #000000;
                          width: 900px;
                          margin: 0 auto;
                        "
                        width="900"
                      >
                        <tbody>
                          <tr>
                            <td
                              class="column column-1"
                              width="100%"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                font-weight: 400;
                                text-align: left;
                                padding-bottom: 5px;
                                padding-top: 5px;
                                vertical-align: top;
                                border-top: 0px;
                                border-right: 0px;
                                border-bottom: 0px;
                                border-left: 0px;
                              "
                            >
                              <table
                                class="table_block block-1"
                                width="100%"
                                border="0"
                                cellpadding="10"
                                cellspacing="0"
                                role="presentation"
                                style="
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                "
                              >
                                <tr>
                                  <td class="pad">
                                    <table
                                      style="
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                        border-collapse: collapse;
                                        width: 100%;
                                        table-layout: fixed;
                                        direction: ltr;
                                        background-color: transparent;
                                        font-family: 'Montserrat', 'Trebuchet MS',
                                          'Lucida Grande', 'Lucida Sans Unicode',
                                          'Lucida Sans', Tahoma, sans-serif;
                                        font-weight: 400;
                                        color: #101112;
                                        text-align: center;
                                        letter-spacing: 0px;
                                        word-break: break-all;
                                      "
                                      width="100%"
                                    >
                                      <thead
                                        style="
                                          vertical-align: top;
                                          background-color: #f2f2f2;
                                          color: #101112;
                                          font-size: 17px;
                                          line-height: 120%;
                                          text-align: center;
                                        "
                                      >
                                        <tr>
                                          <th
                                            width="16.666666666666668%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΜΑΘΗΤΗΣ/ΤΡΙΑ<br />
                                          </th>
                                          <th
                                            width="16.666666666666668%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΔΙΔΑΚΤΡΑ
                                          </th>
                                          <th
                                            width="16.666666666666668%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ
                                          </th>
                                          <th
                                            width="16.666666666666668%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΗΜ. ΠΛΗΡΩΜΗΣ
                                          </th>
                                          <th
                                            width="16.666666666666668%"
                                            style="
                                              padding: 10px;
                                              word-break: break-word;
                                              font-weight: 700;
                                              border-top: 3px solid #dddddd;
                                              border-right: 3px solid #dddddd;
                                              border-bottom: 3px solid #dddddd;
                                              border-left: 3px solid #dddddd;
                                            "
                                          >
                                            ΠΟΣΟ
                                          </th>
                                        </tr>
                                      </thead>
                                      ${generateUserRows()}
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- End -->
    </body>
  </html>  
  `;

  let htmlTemplateMonday = `
  <!DOCTYPE html>
<html
  xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
  lang="en"
>
  <head>
    <title></title>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <!--[if mso
      ]><xml
        ><o:OfficeDocumentSettings
          ><o:PixelsPerInch>96</o:PixelsPerInch
          ><o:AllowPNG /></o:OfficeDocumentSettings></xml
    ><![endif]-->
    <!--[if !mso]><!-->
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900"
      rel="stylesheet"
      type="text/css"
    />
    <!--<![endif]-->
    <style>
      * {
        box-sizing: border-box;
      }

      body {
        margin: 0;
        padding: 0;
      }

      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
      }

      #MessageViewBody a {
        color: inherit;
        text-decoration: none;
      }

      p {
        line-height: inherit;
      }

      .desktop_hide,
      .desktop_hide table {
        mso-hide: all;
        display: none;
        max-height: 0px;
        overflow: hidden;
      }

      .image_block img + div {
        display: none;
      }

      sup,
      sub {
        line-height: 0;
        font-size: 75%;
      }

      @media (max-width: 768px) {
        .desktop_hide table.icons-inner {
          display: inline-block !important;
        }

        .icons-inner {
          text-align: center;
        }

        .icons-inner td {
          margin: 0 auto;
        }

        .mobile_hide {
          display: none;
        }

        .row-content {
          width: 100% !important;
        }

        .stack .column {
          width: 100%;
          display: block;
        }

        .mobile_hide {
          min-height: 0;
          max-height: 0;
          max-width: 0;
          overflow: hidden;
          font-size: 0px;
        }

        .desktop_hide,
        .desktop_hide table {
          display: table !important;
          max-height: none !important;
        }
      }
    </style>
    <!--[if mso
      ]><style>
        sup,
        sub {
          font-size: 100% !important;
        }
        sup {
          mso-text-raise: 10%;
        }
        sub {
          mso-text-raise: -10%;
        }
      </style>
    <![endif]-->
  </head>

  <body
    class="body"
    style="
      background-color: #ffffff;
      margin: 0;
      padding: 0;
      -webkit-text-size-adjust: none;
      text-size-adjust: none;
    "
  >
    <table
      class="nl-container"
      width="100%"
      border="0"
      cellpadding="0"
      cellspacing="0"
      role="presentation"
      style="
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
        background-color: #ffffff;
      "
    >
      <tbody>
        <tr>
          <td>
            <table
              class="row row-1"
              align="center"
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      class="row-content stack"
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-radius: 0;
                        color: #000000;
                        width: 900px;
                        margin: 0 auto;
                      "
                      width="900"
                    >
                      <tbody>
                        <tr>
                          <td
                            class="column column-1"
                            width="75%"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              padding-bottom: 5px;
                              padding-top: 5px;
                              vertical-align: middle;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                          >
                            <table
                              class="heading_block block-1"
                              width="100%"
                              border="0"
                              cellpadding="10"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                              "
                            >
                              <tr>
                                <td class="pad">
                                  <h1
                                    style="
                                      margin: 0;
                                      color: #7747ff;
                                      direction: ltr;
                                      font-family: 'Montserrat', 'Trebuchet MS',
                                        'Lucida Grande', 'Lucida Sans Unicode',
                                        'Lucida Sans', Tahoma, sans-serif;
                                      font-size: 38px;
                                      font-weight: 700;
                                      letter-spacing: normal;
                                      line-height: 120%;
                                      text-align: center;
                                      margin-top: 0;
                                      margin-bottom: 0;
                                      mso-line-height-alt: 45.6px;
                                    "
                                  >
                                    ${
                                      window.location.hostname.includes(
                                        "oramapaideias"
                                      )
                                        ? `<img
                                        src="https://oramapaideias.edupal.gr/resources/logos/orama_paideias.png"
                                        style="
                                          display: block;
                                          height: auto;
                                          border: 0;
                                          max-width: 135px;
                                          width: 100%;
                                        "
                                        width="135"
                                        alt="Logo"
                                        title="Logo"
                                      />`
                                        : `<img
                                        src="https://md.edupal.gr/resources/logos/edupal.png"
                                        style="
                                          display: block;
                                          height: auto;
                                          border: 0;
                                          max-width: 135px;
                                          width: 100%;
                                        "
                                        width="135"
                                        alt="Logo"
                                        title="Logo"
                                      />`
                                    }
                                  </h1>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td
                            class="column column-2"
                            width="25%"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              padding-bottom: 5px;
                              padding-top: 5px;
                              vertical-align: middle;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                          >
                            <table
                              class="paragraph_block block-1"
                              width="100%"
                              border="0"
                              cellpadding="10"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                word-break: break-word;
                              "
                            >
                              <tr>
                                <td class="pad">
                                  <div
                                    style="
                                      color: #101112;
                                      direction: ltr;
                                      font-family: 'Montserrat', 'Trebuchet MS',
                                        'Lucida Grande', 'Lucida Sans Unicode',
                                        'Lucida Sans', Tahoma, sans-serif;
                                      font-size: 16px;
                                      font-weight: 400;
                                      letter-spacing: 0px;
                                      line-height: 120%;
                                      text-align: center;
                                      mso-line-height-alt: 19.2px;
                                    "
                                  >
                                    <p style="margin: 0; margin-bottom: 16px">
                                      <strong>Οικονομικά δεδομένα</strong>
                                    </p>
                                    <p style="margin: 0">
                                      <strong>#weekPeriod</strong>
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="row row-3"
              align="center"
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      class="row-content stack"
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-radius: 0;
                        color: #000000;
                        width: 900px;
                        margin: 0 auto;
                      "
                      width="900"
                    >
                      <tbody>
                        <tr>
                          <td
                            class="column column-1"
                            width="100%"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              padding-bottom: 5px;
                              padding-top: 5px;
                              vertical-align: top;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                          >
                            <table
                              class="table_block block-1"
                              width="100%"
                              border="0"
                              cellpadding="10"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                              "
                            >
                              <tr>
                                <td class="pad">
                                  <table
                                    style="
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-collapse: collapse;
                                      width: 100%;
                                      table-layout: fixed;
                                      direction: ltr;
                                      background-color: transparent;
                                      font-family: 'Montserrat', 'Trebuchet MS',
                                        'Lucida Grande', 'Lucida Sans Unicode',
                                        'Lucida Sans', Tahoma, sans-serif;
                                      font-weight: 400;
                                      color: #101112;
                                      text-align: center;
                                      letter-spacing: 0px;
                                      word-break: break-all;
                                    "
                                    width="100%"
                                  >
                                    <thead
                                      style="
                                        vertical-align: top;
                                        background-color: #6225e6;
                                        color: #ffffff;
                                        font-size: 14px;
                                        line-height: 120%;
                                        text-align: center;
                                      "
                                    >
                                      <tr>
                                        <th
                                          width="90%"
                                          style="
                                            padding: 10px;
                                            word-break: break-word;
                                            font-weight: 700;
                                            border-top: 3px solid #dddddd;
                                            border-right: 3px solid #dddddd;
                                            border-bottom: 3px solid #dddddd;
                                            border-left: 3px solid #dddddd;
                                          "
                                        >
                                          ΣΥΝΟΛΙΚΟ ΠΟΣΟ
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody
                                      style="
                                        vertical-align: top;
                                        font-size: 16px;
                                        line-height: 120%;
                                      "
                                    >
                                      <tr>
                                        <td
                                          width="12.5%"
                                          style="
                                            padding: 10px;
                                            word-break: break-word;
                                            border-top: 3px solid #dddddd;
                                            border-right: 3px solid #dddddd;
                                            border-bottom: 3px solid #dddddd;
                                            border-left: 3px solid #dddddd;
                                          "
                                        >
                                          #mondayammount €&nbsp;<br />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="row row-2"
              align="center"
              width="100%"
              border="0"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      class="row-content stack"
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      role="presentation"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-radius: 0;
                        color: #000000;
                        width: 900px;
                        margin: 0 auto;
                      "
                      width="900"
                    >
                      <tbody>
                        <tr>
                          <td
                            class="column column-1"
                            width="100%"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              padding-bottom: 5px;
                              padding-top: 5px;
                              vertical-align: top;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                          >
                            <table
                              class="table_block block-1"
                              width="100%"
                              border="0"
                              cellpadding="10"
                              cellspacing="0"
                              role="presentation"
                              style="
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                              "
                            >
                              <tr>
                                <td class="pad">
                                  <table
                                    style="
                                      mso-table-lspace: 0pt;
                                      mso-table-rspace: 0pt;
                                      border-collapse: collapse;
                                      width: 100%;
                                      table-layout: fixed;
                                      direction: ltr;
                                      background-color: transparent;
                                      font-family: 'Montserrat', 'Trebuchet MS',
                                        'Lucida Grande', 'Lucida Sans Unicode',
                                        'Lucida Sans', Tahoma, sans-serif;
                                      font-weight: 400;
                                      color: #101112;
                                      text-align: center;
                                      letter-spacing: 0px;
                                      word-break: break-all;
                                    "
                                    width="100%"
                                  >
                                    <thead
                                      style="
                                        vertical-align: top;
                                        background-color: #f2f2f2;
                                        color: #101112;
                                        font-size: 17px;
                                        line-height: 120%;
                                        text-align: center;
                                      "
                                    >
                                      <tr>
                                        <th
                                          width="16.666666666666668%"
                                          style="
                                            padding: 10px;
                                            word-break: break-word;
                                            font-weight: 700;
                                            border-top: 3px solid #dddddd;
                                            border-right: 3px solid #dddddd;
                                            border-bottom: 3px solid #dddddd;
                                            border-left: 3px solid #dddddd;
                                          "
                                        >
                                          ΜΑΘΗΤΗΣ/ΤΡΙΑ<br />
                                        </th>
                                        <th
                                          width="16.666666666666668%"
                                          style="
                                            padding: 10px;
                                            word-break: break-word;
                                            font-weight: 700;
                                            border-top: 3px solid #dddddd;
                                            border-right: 3px solid #dddddd;
                                            border-bottom: 3px solid #dddddd;
                                            border-left: 3px solid #dddddd;
                                          "
                                        >
                                          ΔΙΔΑΚΤΡΑ
                                        </th>
                                        <th
                                          width="16.666666666666668%"
                                          style="
                                            padding: 10px;
                                            word-break: break-word;
                                            font-weight: 700;
                                            border-top: 3px solid #dddddd;
                                            border-right: 3px solid #dddddd;
                                            border-bottom: 3px solid #dddddd;
                                            border-left: 3px solid #dddddd;
                                          "
                                        >
                                          ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ
                                        </th>
                                        <th
                                          width="16.666666666666668%"
                                          style="
                                            padding: 10px;
                                            word-break: break-word;
                                            font-weight: 700;
                                            border-top: 3px solid #dddddd;
                                            border-right: 3px solid #dddddd;
                                            border-bottom: 3px solid #dddddd;
                                            border-left: 3px solid #dddddd;
                                          "
                                        >
                                          ΗΜ. ΠΛΗΡΩΜΗΣ
                                        </th>
                                        <th
                                          width="16.666666666666668%"
                                          style="
                                            padding: 10px;
                                            word-break: break-word;
                                            font-weight: 700;
                                            border-top: 3px solid #dddddd;
                                            border-right: 3px solid #dddddd;
                                            border-bottom: 3px solid #dddddd;
                                            border-left: 3px solid #dddddd;
                                          "
                                        >
                                          ΠΟΣΟ
                                        </th>
                                      </tr>
                                    </thead>
                                    #users
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- End -->
  </body>
</html>
  `;

  const handlePrint = () => {
    const startOfWeek = getStartOfWeek(currentDate);
    const daysOfWeek = Array.from({ length: 7 }).map((_, index) => {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + index);
      return day;
    });

    // Calculate total amounts for each day of the current week
    const dailyAmounts = daysOfWeek.map((day) => {
      const tuitionForDay = filterTuitionByDay(studentTuition, day);
      return tuitionForDay.reduce((sum, item) => sum + item.amount, 0);
    });

    // Calculate total amount for the whole week
    const totalAmount = dailyAmounts.reduce((sum, amount) => sum + amount, 0);

    // Replace the placeholders in the htmlTemplate with the calculated amounts and headerText for #weekPeriod
    const htmlContent = htmlTemplate
      .replace(/#mondayammount/g, dailyAmounts[0])
      .replace(/#tuesdayammount/g, dailyAmounts[1])
      .replace(/#wednesdayammount/g, dailyAmounts[2])
      .replace(/#thursdayammount/g, dailyAmounts[3])
      .replace(/#fridayammount/g, dailyAmounts[4])
      .replace(/#saturdayammount/g, dailyAmounts[5])
      .replace(/#sundayammount/g, dailyAmounts[6])
      .replace(/#totalammount/g, totalAmount)
      .replace(/#weekPeriod/g, headerText); // Replace #weekPeriod with headerText

    // Create a hidden iframe for printing
    const iframe = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.style.position = "absolute";
    iframe.style.width = "0px";
    iframe.style.height = "0px";
    iframe.style.border = "none";

    iframe.onload = () => {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
      setTimeout(() => {
        iframe.remove();
      }, 1000);
    };

    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(htmlContent);
    doc.close();
  };

  const handleDayPrint = (day) => {
    const startOfWeek = getStartOfWeek(currentDate);

    // Create an array of all the days in the week
    const daysOfWeek = Array.from({ length: 7 }).map((_, index) => {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + index);
      return day;
    });

    // Calculate total amounts for each day of the current week
    const dailyAmounts = daysOfWeek.map((day) => {
      const tuitionForDay = filterTuitionByDay(studentTuition, day);
      return tuitionForDay.reduce((sum, item) => sum + item.amount, 0);
    });

    // Format the selected day into Greek date format like Δευτέρα 2 Σεπτεμβρίου
    const formattedDay = formatDateToGreek(daysOfWeek[day]);

    // Replace the placeholders in the htmlTemplate with the calculated amounts and formatted day
    const htmlContent = htmlTemplateMonday
      .replace(/#mondayammount/g, dailyAmounts[day]) // Replace with the specific day's amount
      .replace(/#weekPeriod/g, formattedDay) // Replace with the specific day and date in Greek
      .replace(/#users/g, generateUserRowsForDay(day)); // Replace with the user rows for the day

    // Create a hidden iframe for printing
    const iframe = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.style.position = "absolute";
    iframe.style.width = "0px";
    iframe.style.height = "0px";
    iframe.style.border = "none";

    iframe.onload = () => {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
      setTimeout(() => {
        iframe.remove();
      }, 1000);
    };

    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(htmlContent);
    doc.close();
  };

  // Helper function to format the date into Greek day and date
  const formatDateToGreek = (date) => {
    const daysInGreek = [
      "Κυριακή",
      "Δευτέρα",
      "Τρίτη",
      "Τετάρτη",
      "Πέμπτη",
      "Παρασκευή",
      "Σάββατο",
    ];

    const monthsInGreek = [
      "Ιανουαρίου",
      "Φεβρουαρίου",
      "Μαρτίου",
      "Απριλίου",
      "Μαΐου",
      "Ιουνίου",
      "Ιουλίου",
      "Αυγούστου",
      "Σεπτεμβρίου",
      "Οκτωβρίου",
      "Νοεμβρίου",
      "Δεκεμβρίου",
    ];

    const dayOfWeek = daysInGreek[date.getDay()]; // Get the day in Greek
    const dayOfMonth = date.getDate(); // Get the day of the month
    const month = monthsInGreek[date.getMonth()]; // Get the month in Greek

    return `${dayOfWeek} ${dayOfMonth} ${month}`;
  };

  return (
    <div className="weekly-slider-container">
      <div className="header">
        <button className="cta cta-shadow" onClick={() => handleWeekChange(-1)}>
          Προηγούμενη
        </button>
        <span className="header__title">
          {/* <span onClick={() => setIsDropdownOpen(!isDropdownOpen)}> */}
          <span>{headerText}</span>
          <BiPrinter
            className="options__print"
            size={"28px"}
            color={"rgb(204, 204, 204)"}
            style={iconStyle("transparent")}
            onClick={() => handlePrint()}
          />
        </span>
        <button className="cta cta-shadow" onClick={() => handleWeekChange(1)}>
          Επόμενη
        </button>
      </div>
      {isDropdownOpen && renderDropdown()}
      <div className="content-container">
        <div className="slider-container">
          <Slider
            {...{
              initialSlide: currentWeekNumber - 1,
              afterChange: (current) => setCurrentWeek(current + 1),
              infinite: true,
              speed: 500,
              slidesToShow: 1,
              slidesToScroll: 1,
            }}
          >
            <div key={currentWeek}>
              {renderChart(startOfWeek)}
              <div className="print-container">
                <div className="print-container__day">
                  <BiPrinter
                    className="options__print"
                    size={"28px"}
                    color={"rgb(204, 204, 204)"}
                    style={iconStyle("transparent")}
                    onClick={() => handleDayPrint(0)}
                  />
                </div>
                <div className="print-container__day">
                  <BiPrinter
                    className="options__print"
                    size={"28px"}
                    color={"rgb(204, 204, 204)"}
                    style={iconStyle("transparent")}
                    onClick={() => handleDayPrint(1)}
                  />
                </div>
                <div className="print-container__day">
                  <BiPrinter
                    className="options__print"
                    size={"28px"}
                    color={"rgb(204, 204, 204)"}
                    style={iconStyle("transparent")}
                    onClick={() => handleDayPrint(2)}
                  />
                </div>
                <div className="print-container__day">
                  <BiPrinter
                    className="options__print"
                    size={"28px"}
                    color={"rgb(204, 204, 204)"}
                    style={iconStyle("transparent")}
                    onClick={() => handleDayPrint(3)}
                  />
                </div>
                <div className="print-container__day">
                  <BiPrinter
                    className="options__print"
                    size={"28px"}
                    color={"rgb(204, 204, 204)"}
                    style={iconStyle("transparent")}
                    onClick={() => handleDayPrint(4)}
                  />
                </div>
                <div className="print-container__day">
                  <BiPrinter
                    className="options__print"
                    size={"28px"}
                    color={"rgb(204, 204, 204)"}
                    style={iconStyle("transparent")}
                    onClick={() => handleDayPrint(5)}
                  />
                </div>
                <div className="print-container__day">
                  <BiPrinter
                    className="options__print"
                    size={"28px"}
                    color={"rgb(204, 204, 204)"}
                    style={iconStyle("transparent")}
                    onClick={() => handleDayPrint(6)}
                  />
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div className="user-info">{renderUsers(startOfWeek, endOfWeek)}</div>
      </div>
    </div>
  );
};

export default WeeklySlider;
