import React, { useState, useEffect } from "react";
import "./image-slider.scss";
import { iconStyle } from "../../utils/generalUtils";
import {
  BiChevronLeft,
  BiChevronRight,
  BiRotateLeft,
  BiRotateRight,
  BiSearch,
  BiZoomIn,
  BiZoomOut,
} from "react-icons/bi";
import { useSelector } from "react-redux";

const ImageSlider = ({ activeAsset, assets, disableDownload }) => {
  const findIndex = (asset) =>
    assets.findIndex((a) => typeof a === "string" && a === asset);
  const [currentIndex, setCurrentIndex] = useState(findIndex(activeAsset));
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const profile = useSelector((state) => state.profile.value);

  useEffect(() => {
    const index = findIndex(activeAsset);
    if (index !== -1) setCurrentIndex(index);
  }, [activeAsset, assets]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : assets.length - 1
    );
    resetTransformations();
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % assets.length);
    resetTransformations();
  };

  const rotateLeft = () => {
    setRotation((prevRotation) => prevRotation - 90);
  };

  const rotateRight = () => {
    setRotation((prevRotation) => prevRotation + 90);
  };

  const zoomIn = () => {
    setScale((prevScale) => prevScale + 0.1);
  };

  const zoomOut = () => {
    setScale((prevScale) => prevScale - 0.1);
  };

  const resetTransformations = () => {
    setRotation(0);
    setScale(1);
  };

  const renderAsset = (asset) => {
    const transformStyle = {
      transform: `rotate(${rotation}deg) scale(${scale})`,
    };

    if (typeof asset === "string") {
      const pdfUrl = `${asset}#toolbar=0`;
      if (asset.endsWith(".pdf") || asset.endsWith(".PDF")) {
        return (
          <iframe
            src={
              disableDownload &&
              profile.user_type != "admin" &&
              profile.user_type != "professor"
                ? pdfUrl
                : asset
            }
            frameBorder="0"
            style={{ width: "100%", height: "100%" }}
          />
        );
      } else if (asset.endsWith(".mp3")) {
        return (
          <audio className="audio-file" controls style={{ width: "100%" }}>
            <source src={asset} type="audio/mpeg" />Ο browser σας δεν
            υποστηρίζει mp3 αρχεία
          </audio>
        );
      } else if (asset.endsWith(".ppt") || asset.endsWith(".pptx")) {
        return (
          <div className="ppt-download">
            <button
              onClick={() => window.open(asset, "_blank")}
              className="ppt-download-button cta cta-fill"
            >
              Λήψη αρχείου ppt
            </button>
          </div>
        );
      } else {
        return (
          <img
            src={asset}
            alt="Slide"
            style={{ ...transformStyle, width: "100%", height: "100%" }}
          />
        );
      }
    }
    return <div>Invalid asset type</div>;
  };

  return (
    <div className="image-slider">
      <button className="asset-container__nav left" onClick={goToPrevious}>
        <BiChevronLeft
          size={"35px"}
          color={"#fff"}
          style={iconStyle("transparent")}
        />
      </button>
      <div className="asset-container">{renderAsset(assets[currentIndex])}</div>
      {typeof assets[currentIndex] === "string" &&
      (assets[currentIndex].endsWith(".png") ||
        assets[currentIndex].endsWith(".jpg") ||
        assets[currentIndex].endsWith(".jpeg") ||
        assets[currentIndex].endsWith(".JPEG") ||
        assets[currentIndex].endsWith(".PNG") ||
        assets[currentIndex].endsWith(".JPG")) ? (
        <div className="asset-container__controls">
          <div className="controls-item" onClick={rotateLeft}>
            <BiRotateLeft
              size={"35px"}
              color={"#fff"}
              style={iconStyle("transparent")}
            />
          </div>
          <div className="controls-item" onClick={zoomOut}>
            <BiZoomOut
              size={"35px"}
              color={"#fff"}
              style={iconStyle("transparent")}
            />
          </div>
          <div className="controls-item" onClick={zoomIn}>
            <BiZoomIn
              size={"35px"}
              color={"#fff"}
              style={iconStyle("transparent")}
            />
          </div>
          <div className="controls-item" onClick={rotateRight}>
            <BiRotateRight
              size={"35px"}
              color={"#fff"}
              style={iconStyle("transparent")}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <button className="asset-container__nav right" onClick={goToNext}>
        <BiChevronRight
          size={"35px"}
          color={"#fff"}
          style={iconStyle("transparent")}
        />
      </button>
      <div className="thumbnail-container">
        {assets.map((asset, index) => {
          const isActive = index === currentIndex;
          const isPdf =
            typeof asset === "string" &&
            (asset.endsWith(".pdf") || asset.endsWith(".PDF"));
          const isAudio = typeof asset === "string" && asset.endsWith(".mp3");
          const isPpt =
            typeof asset === "string" &&
            (asset.endsWith(".ppt") || asset.endsWith(".pptx"));

          let className = "";
          if (isActive) {
            className += "active ";
          }
          if (isPdf) {
            className += "pdf";
          } else if (isAudio) {
            className += "audio";
          } else if (isPpt) {
            className += "ppt";
          }

          return (
            <img
              key={index}
              src={
                typeof asset === "string" &&
                !asset.endsWith(".pdf") &&
                !asset.endsWith(".PDF") &&
                !asset.endsWith(".mp3") &&
                !asset.endsWith(".ppt") &&
                !asset.endsWith(".pptx")
                  ? asset
                  : asset.endsWith(".mp3")
                  ? "../../../resources/icons/audio-icon.png"
                  : asset.endsWith(".ppt") || asset.endsWith(".pptx")
                  ? "../../../resources/icons/ppt-icon.png"
                  : "../../../resources/icons/pdf-icon.png"
              }
              alt="Thumbnail"
              className={className}
              onClick={() => {
                setCurrentIndex(index);
                resetTransformations();
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ImageSlider;
